import axios, { AxiosResponse } from 'axios';

import generalUtils from '~/utils/general-utils';
import dateUtils from '~/utils/date-utils';
import constants from '~/utils/constants';
import {
    ApiAssignment,
    ApiTask,
    ApiTaskScheduler,
    AxiosApiResponse,
    IApiResponse,
    IPostDriverAssignmentsResponse,
    PaginationMetadata
} from '../types';

import DriverApi from '../DriverApi';
import { getParamErrorTaskWindowSlots } from '../utils/validate';
import {
    TasksApiErrors,
    GetTasksParams,
    GetTasksConfig,
    GetTaskParams,
    GetMetricsParams,
    GetMetricsResponse,
    GetMetricsConfig,
    StartSchedulerArgs,
    StartSchedulerResponse,
    StartSchedulerParams,
    StopSchedulerResponse,
    JoinTasksParams,
    SplitTaskResponseData,
    GeneratePairingsParams,
    GenerateDepotPairsParams,
    GenerateDepotPairsResponse,
    AddOrUpdateTaskData,
    UpdateResponse,
    DeleteTaskResponse,
    DeleteTasksResponse,
    TaskLockTasksResponse,
    OrderLockTasksResponse,
    UnlockTasksResponse,
    UnassignTaskResponse,
    SplitTaskByInvoiceParams,
    SplitTaskByInventoryParams,
    SplitTaskByInvoiceResponse,
    SplitTaskByInventoryResponse
} from './types';

/**
 * The maximum number of fields/columns we can sort the getTasks() results by
 */
const GET_TASKS_MAX_SORT_FIELDS = 5;

/**
 * Implementations of API methods under the `/task` or `/tasks` paths
 *
 * @category API
 */
export default class TasksApi {
    /**
     * Path of the single task api endpoint
     */
    private static readonly taskPath = '/task';

    /**
     * Path of the multi-task api endpoint
     */
    private static readonly tasksPath = '/tasks';

    /**
     * Key for react-query
     */
    static readonly REACT_QUERY_KEY_TASK = 'task';

    /**
     * Sends a GET /tasks request with the provided params
     */
    static get(
        params: GetTasksParams = {
            page: 0,
            limit: 1,
            date: undefined,
            extent: undefined,
            status: undefined
        }
    ): Promise<AxiosApiResponse<ApiTask[], PaginationMetadata>> {
        const { date, extent, sort } = params;

        if (sort && sort.length > GET_TASKS_MAX_SORT_FIELDS) {
            return Promise.reject(TasksApiErrors.MAX_SORT_FIELDS);
        }

        if (sort?.length && extent) {
            return Promise.reject(
                TasksApiErrors.INCOMPATIBLE_SORT_EXTENT_FIELDS
            );
        }

        const convertedDate = date
            ? dateUtils.convertToISODateOnly(date)
            : undefined;

        const convertedSort = sort
            ?.map((sortConfig) => {
                const { id, desc } = sortConfig;
                const sortDir = desc
                    ? constants.sortOrder.DESC
                    : constants.sortOrder.ASC;
                return `${id},${sortDir}`;
            })
            .join(';');

        const convertedExtent = (!sort?.length && extent) || undefined;

        const config: GetTasksConfig = {
            params: {
                ...params,
                date: convertedDate || undefined,
                extent: convertedExtent,
                sort: sort?.length ? convertedSort : undefined
            }
        };

        return axios.get(this.tasksPath, config);
    }

    static getTask(
        id: string,
        params: GetTaskParams = {}
    ): Promise<AxiosApiResponse<ApiTask>> {
        const { extent: requestedExtent = '' } = params;

        const supported = constants.allSupportedTaskExtents.split(',');
        const requested = requestedExtent.toString().split(',');
        const extents = [...new Set([...supported, ...requested])].join(',');

        return axios.get(`${this.taskPath}/${id}`, {
            params: { ...params, extent: extents }
        });
    }

    static getTaskAssignments(
        id: string
    ): Promise<AxiosApiResponse<ApiAssignment[], PaginationMetadata>> {
        return axios.get(`${this.taskPath}/${id}/assignments?signed=true`);
    }

    static getMetrics(
        params: GetMetricsParams = {}
    ): Promise<GetMetricsResponse> {
        const { date, start_date: startDate, end_date: endDate } = params;
        const isoDate = date
            ? dateUtils.convertToISODateOnly(date) ?? undefined
            : undefined;
        const isoStartDate = startDate
            ? dateUtils.convertToISODateOnly(startDate) ?? undefined
            : undefined;
        const isoEndDate = endDate
            ? dateUtils.convertToISODateOnly(endDate) ?? undefined
            : undefined;
        const config: GetMetricsConfig = {
            params: {
                ...params,
                date: isoDate ? [isoDate] : undefined,
                start_date: isoStartDate,
                end_date: isoEndDate
            }
        };

        return axios.get(`${this.tasksPath}/metrics`, config);
    }

    static startScheduler(
        args: StartSchedulerArgs = {}
    ): Promise<StartSchedulerResponse> {
        const dateArg = args?.date;
        if (!dateArg) {
            return Promise.reject(TasksApiErrors.MISSING_DATE);
        }
        const params: StartSchedulerParams = {
            ...args,
            date: dateUtils.convertToISODateOnly(dateArg) ?? undefined
        };

        return axios.post(`${this.tasksPath}?action=lr_schedule`, params);
    }

    static stopScheduler(): Promise<StopSchedulerResponse> {
        return axios.post(`${this.tasksPath}?action=lr_stop`, {});
    }

    static joinTasks({
        id,
        joinedTask
    }: JoinTasksParams): Promise<AxiosApiResponse<ApiTask>> {
        return axios.post(`${this.taskPath}/${id}?action=join`, { joinedTask });
    }

    static splitTask(
        id: string
    ): Promise<AxiosApiResponse<SplitTaskResponseData>> {
        return axios.post(`${this.taskPath}/${id}?action=split`, {});
    }

    /**
     * @returns api response with pairingRunId
     */
    static generatePairings({
        taskIds,
        unassignedTaskIds
    }: GeneratePairingsParams = {}): Promise<AxiosApiResponse<string>> {
        if (!taskIds?.length)
            return Promise.reject(TasksApiErrors.MISSING_TASKIDS);

        return axios.post(`${this.tasksPath}/pairings?action=generate`, {
            taskIds,
            unassignedTaskIds
        });
    }

    static generateDepotPairs({
        taskIds
    }: GenerateDepotPairsParams = {}): Promise<GenerateDepotPairsResponse> {
        if (!taskIds?.length)
            return Promise.reject(TasksApiErrors.MISSING_TASKIDS);
        return axios.post(`${this.tasksPath}/pairings?action=depot_pair`, {
            taskIds
        });
    }

    static addTask(
        payload: AddOrUpdateTaskData
    ): Promise<AxiosApiResponse<ApiTask>> {
        const timeSlotError = getParamErrorTaskWindowSlots(payload);
        if (timeSlotError) {
            return Promise.reject(timeSlotError);
        }
        return axios.put(`${this.taskPath}`, payload);
    }

    static addTasks(
        payload: AddOrUpdateTaskData[]
    ): Promise<AxiosApiResponse<Record<string, string[]>>> {
        const isError = payload.some((each) =>
            getParamErrorTaskWindowSlots(each)
        );
        if (isError) {
            return Promise.reject(isError);
        }
        return axios.put(`${this.tasksPath}`, payload);
    }

    static addOperationalStop(payload: {
        tasks: AddOrUpdateTaskData[];
        taskId: string;
        position?: number;
    }): Promise<AxiosApiResponse<Record<string, string[]>>> {
        const { taskId, ...rest } = payload;
        return axios.post(`/operationalstops/task/${taskId}`, rest);
    }

    static update(
        id: string,
        payload: Record<string, unknown>
    ): Promise<UpdateResponse> {
        return axios.patch(`${this.taskPath}/${id}`, { ...payload });
    }

    static deleteTask(id: string): Promise<DeleteTaskResponse> {
        return axios.delete(`${this.taskPath}/${id}`);
    }

    static deleteTasks(taskIds: string[]): Promise<DeleteTasksResponse> {
        return axios.delete(`${this.tasksPath}`, {
            data: { taskIds }
        });
    }

    /**
     * Locks all tasks identified by the provided `taskIds`
     *
     * @param {string[]} taskIds
     * @returns {Promise<TaskLockTasksResponse>}
     */
    static taskLockTasks(taskIds: string[]): Promise<TaskLockTasksResponse> {
        return axios.patch(`${this.tasksPath}?action=lock`, {
            taskIds
        });
    }

    /**
     * Order locks all tasks identified by the provided `taskIds`
     *
     * @param {string[]} taskIds
     * @returns {Promise<OrderLockTasksResponse>}
     */
    static orderLockTasks(taskIds: string[]): Promise<OrderLockTasksResponse> {
        return axios.patch(`${this.tasksPath}?action=lock_order`, {
            taskIds
        });
    }

    /**
     * Unlocks all tasks identified by the provided `taskIds`
     *
     * @param {string[]} taskIds
     * @returns {Promise<UnlockTasksResponse>}
     */
    static unlockTasks(
        taskIds: string[]
    ): Promise<AxiosResponse<UnlockTasksResponse>> {
        return axios.patch(`${this.tasksPath}?action=unlock`, {
            taskIds
        });
    }

    static unassignTask(
        taskId: string
    ): Promise<AxiosResponse<UnassignTaskResponse>> {
        return axios.patch(`${this.taskPath}/${taskId}?action=unassign`);
    }

    /**
     * Bulk unassign specific tasks
     *
     * @param {string} taskIds - the task IDs
     * @returns {Promise<ApiTaskScheduler>}
     */
    static unassignTasks(
        taskIds: string[]
    ): Promise<AxiosApiResponse<ApiTaskScheduler>> {
        if (!taskIds) {
            return Promise.reject(TasksApiErrors.MISSING_TASKIDS);
        }

        const validIds =
            Array.isArray(taskIds) &&
            !!taskIds.length &&
            taskIds.every((taskId) => generalUtils.isValidUUID(taskId));
        if (!validIds) {
            return Promise.reject(TasksApiErrors.INVALID_TASKIDS);
        }

        return axios.patch<IApiResponse>(`${this.tasksPath}?action=unassign`, {
            taskIds
        });
    }

    /**
     * Bulk cancel specific tasks
     *
     * @param {string[]} taskIds - the task IDs
     * @returns {Promise<ApiTaskScheduler>}
     */
    static cancelTasks(
        taskIds: string[]
    ): Promise<AxiosApiResponse<ApiTaskScheduler>> {
        if (!taskIds) {
            return Promise.reject(TasksApiErrors.MISSING_TASKIDS);
        }

        const validIds =
            Array.isArray(taskIds) &&
            !!taskIds.length &&
            taskIds.every((taskId) => generalUtils.isValidUUID(taskId));
        if (!validIds) {
            return Promise.reject(TasksApiErrors.INVALID_TASKIDS);
        }

        return axios.patch(`${this.tasksPath}?action=cancel`, { taskIds });
    }

    private static validateReassignParams(
        taskIds: string[],
        driverId: string
    ): TasksApiErrors | void {
        if (!taskIds) {
            return TasksApiErrors.MISSING_TASKIDS;
        }

        const validIds =
            Array.isArray(taskIds) &&
            !!taskIds.length &&
            taskIds.every((taskId) => generalUtils.isValidUUID(taskId));
        if (!validIds) {
            return TasksApiErrors.INVALID_TASKIDS;
        }

        if (!driverId) {
            return TasksApiErrors.MISSING_DRIVER_ID;
        }

        if (!generalUtils.isValidUUID(driverId)) {
            return TasksApiErrors.INVALID_DRIVER_ID;
        }
    }

    /**
     * Bulk reassign specific tasks
     *
     * @param {string[]} taskIds - the task IDs
     * @param {string} driverId - the driver ID to reassign tasks to
     * @returns {Promise<ApiTaskScheduler>}
     */
    static reassignTasks(
        taskIds: string[],
        driverId: string
    ): Promise<AxiosApiResponse<ApiTaskScheduler>> {
        const validationError = TasksApi.validateReassignParams(
            taskIds,
            driverId
        );
        if (validationError) {
            return Promise.reject(validationError);
        }

        return axios.patch(`${this.tasksPath}?action=reassign`, {
            taskIds,
            driver: driverId
        });
    }

    /**
     * Bulk reassign specific tasks. Legacy method, prefer using new API
     * endpoint.
     *
     * This "legacy" approach, used in a similar fashion but without
     * bulk capabilities in Solar, performs the following actions:
     * 1. Unassigns the selected tasks
     * 2. Creates assignments for the unassigned tasks with the new driver
     * 3. Run Manual RTA to calculate ETAs and place the new assignment
     * where they belong in the schedule.
     *
     * - If the unassignment fails, the tasks cannot be reassigned
     * - If the reassignment fails, the tasks remain unassigned
     * - If RTA fails, we do nothing. The schedule is still "valid",
     * but may look out of order until the next RTA run.
     *
     * @todo \@klaframboise Remove once new reassign endpoint is available
     *  https://wisesys.atlassian.net/browse/DISP-550
     * @param {string[]} taskIds - the task IDs
     * @param {string} driverId - the driver ID to reassign tasks to
     * @returns {Promise<AxiosApiResponse<IPostDriverAssignmentsResponse>>}
     */
    static async legacyReassignTasks(
        taskIds: string[],
        driverId: string
    ): Promise<AxiosApiResponse<IPostDriverAssignmentsResponse>> {
        const validationError = TasksApi.validateReassignParams(
            taskIds,
            driverId
        );
        if (validationError) {
            return Promise.reject(validationError);
        }

        try {
            // 1. Unassign tasks
            await TasksApi.unassignTasks(taskIds);
        } catch (error) {
            return Promise.reject(TasksApiErrors.FAILED_TO_UNASSIGN);
        }

        // 2. Post new assignments with new driver
        const tasksToDispatch = taskIds.map((taskId) => {
            return {
                id: taskId,
                pickupTime: Date.now(),
                deliveryTime: Date.now()
            };
        });
        const dispatchResult = await DriverApi.dispatchTasks(
            driverId,
            tasksToDispatch
        );

        try {
            // 3. Run RTA
            await DriverApi.adjustSchedule(driverId);
        } catch (error) {
            // do nothing. Reassignment was successful, but subsequent RTA failed. Schedule may be out of order
            // (i.e. new assignments at top of schedule regardless of ETA) if this occurs.
        }
        return dispatchResult;
    }

    /**
     * Accepts assigned tasks and dispatches driver
     *
     * @param {string} driverId - the driver ID
     * @param {string null} date - the date
     * @returns {Promise<ApiTaskScheduler>}
     */
    static acceptAssignedTasks(
        driverId: string,
        date: string | null,
        routeIds?: string[]
    ): Promise<AxiosApiResponse<ApiTaskScheduler>> {
        if (!driverId) {
            return Promise.reject(TasksApiErrors.MISSING_DRIVER_ID);
        }

        if (!generalUtils.isValidUUID(driverId)) {
            return Promise.reject(TasksApiErrors.INVALID_DRIVER_ID);
        }

        return axios.post<IApiResponse>(
            `${this.tasksPath}?action=accept_assigned`,
            { driverId, date, ...(routeIds && { routeIds }) }
        );
    }

    static splitTaskByInvoice({
        taskId,
        payload
    }: SplitTaskByInvoiceParams): Promise<
        AxiosResponse<SplitTaskByInvoiceResponse>
    > {
        const REQUIRED_PAYLOAD_LENGTH = 2;

        if (!taskId) {
            return Promise.reject(TasksApiErrors.MISSING_TASK_ID);
        }

        if ((payload ?? []).length !== REQUIRED_PAYLOAD_LENGTH) {
            return Promise.reject(TasksApiErrors.INVALID_PAYLOAD_LENGTH);
        }

        return axios.post(
            `${this.taskPath}/${taskId}?action=split_by_quantity`,
            payload
        );
    }

    static splitTaskByInventory({
        taskId,
        payload
    }: SplitTaskByInventoryParams): Promise<
        AxiosResponse<SplitTaskByInventoryResponse>
    > {
        const REQUIRED_PAYLOAD_LENGTH = 2;

        if (!taskId) {
            return Promise.reject(TasksApiErrors.MISSING_TASK_ID);
        }

        if ((payload ?? []).length !== REQUIRED_PAYLOAD_LENGTH) {
            return Promise.reject(TasksApiErrors.INVALID_PAYLOAD_LENGTH);
        }

        return axios.post(
            `${this.taskPath}/${taskId}?action=split_by_quantity`,
            payload
        );
    }

    /**
     * Accepts assigned tasks and dispatches driver
     *
     * @param {string} date - an ISO-formatted date string (eg yyyy-mm-dd)
     * @returns {Promise<ApiTaskScheduler>}
     */
    static dispatchAllRoutes(
        date: string
    ): Promise<AxiosApiResponse<ApiTaskScheduler>> {
        if (!date) {
            return Promise.reject(TasksApiErrors.MISSING_DATE);
        }

        if (
            !dateUtils.checkIsDateStringValidDate(date) ||
            !dateUtils.checkIsYearMonthDayFormat(date)
        ) {
            return Promise.reject(TasksApiErrors.INVALID_DATE);
        }

        return axios.post<IApiResponse>(`${this.tasksPath}?action=lr_accept`, {
            date
        });
    }

    static rescheduleTasks({
        newDate,
        taskIds
    }: {
        newDate: string;
        taskIds: string[];
    }): Promise<AxiosApiResponse> {
        if (!newDate) return Promise.reject('Missing newDate');

        if (!taskIds.length) {
            return Promise.reject('Missing taskIds');
        }

        const params = {
            newDate,
            taskIds
        };

        return axios.patch(`${this.tasksPath}?action=postpone`, params);
    }

    static startSuggestions(date: string) {
        if (!date) {
            return Promise.reject(TasksApiErrors.MISSING_DATE);
        }

        return axios.post(`${this.tasksPath}?action=lr_suggest`, { date });
    }
}

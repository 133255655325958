import { DateTime, Zone } from 'luxon';
import { isEmpty } from 'lodash';
import { TaskTypeRecord, TimeWindow } from '~/api/types';
import { AddTaskModalTimeWindow, ClientTask } from '../utils/addTaskModalUtils';

const SINGLE_TASK_START_TIME_OFFSET = { hours: 0 };
const SINGLE_TASK_END_TIME_OFFSET = { hours: 2 };
const PICKUP_TASK_START_TIME_OFFSET = { hours: 0 };
const PICKUP_TASK_END_TIME_OFFSET = { hours: 2 };
const DELIVERY_TASK_START_TIME_OFFSET = { hours: 3 };
const DELIVERY_TASK_END_TIME_OFFSET = { hours: 5 };

const getZoneAdjustedJsDate = (
    dateTime: DateTime,
    zone?: string | Zone,
    keepLocalTime = true
): Date => {
    return dateTime
        .setZone(zone, {
            keepLocalTime
        })
        .toJSDate();
};

const getDefaultSingleTaskTimeWindow = (
    zone?: string | Zone
): TimeWindow<Date>[] => {
    const startDateTime = DateTime.now().plus(SINGLE_TASK_START_TIME_OFFSET);
    const endDateTime = DateTime.now().plus(SINGLE_TASK_END_TIME_OFFSET);
    return [
        {
            start: getZoneAdjustedJsDate(startDateTime, zone),
            end: getZoneAdjustedJsDate(endDateTime, zone)
        }
    ];
};

const getDefaultTwoPartTaskTimeWindow = (
    zone?: string | Zone
): Required<TaskTypeRecord<TimeWindow<Date>[]>> => {
    const pickupStartDateTime = DateTime.now().plus(
        PICKUP_TASK_START_TIME_OFFSET
    );
    const pickupEndDateTime = DateTime.now().plus(PICKUP_TASK_END_TIME_OFFSET);
    const deliveryStartDateTime = DateTime.now().plus(
        DELIVERY_TASK_START_TIME_OFFSET
    );
    const deliveryEndDateTime = DateTime.now().plus(
        DELIVERY_TASK_END_TIME_OFFSET
    );

    const pickup = [
        {
            start: getZoneAdjustedJsDate(pickupStartDateTime, zone),
            end: getZoneAdjustedJsDate(pickupEndDateTime, zone)
        }
    ];

    const delivery = [
        {
            start: getZoneAdjustedJsDate(deliveryStartDateTime, zone),
            end: getZoneAdjustedJsDate(deliveryEndDateTime, zone)
        }
    ];

    return { pickup, delivery };
};

const hasCustomTimeWindow = (
    timeWindow?: AddTaskModalTimeWindow[]
): timeWindow is TimeWindow<Date>[] => {
    return Boolean(timeWindow && !isEmpty(timeWindow[0]));
};

const getDefaultTaskTimeWindows = ({
    isTwoPartTask = false,
    zone
}: {
    isTwoPartTask?: boolean;
    zone?: string | Zone;
}) => {
    if (isTwoPartTask) {
        const { pickup: pickupTimeWindows, delivery: deliveryTimeWindows } =
            getDefaultTwoPartTaskTimeWindow(zone);

        return {
            pickupTimeWindows,
            deliveryTimeWindows
        };
    }

    return {
        pickupTimeWindows: getDefaultSingleTaskTimeWindow(zone),
        deliveryTimeWindows: getDefaultSingleTaskTimeWindow(zone)
    };
};

export const getSelectedTaskTimeWindows = ({
    isTwoPartTask,
    customMapping = {},
    zone
}: {
    isTwoPartTask?: boolean;
    customMapping?: ClientTask;
    zone?: string | Zone;
} = {}) => {
    const {
        pickupTimeWindows: customPickupTimeWindows,
        deliveryTimeWindows: customDeliveryTimeWindows
    } = customMapping;

    const hasCustomPickupTimeWindows = hasCustomTimeWindow(
        customPickupTimeWindows
    );
    const hasCustomDeliveryTimeWindows = hasCustomTimeWindow(
        customDeliveryTimeWindows
    );

    const {
        pickupTimeWindows: defaultPickupTimeWindows,
        deliveryTimeWindows: defaultDeliveryTimeWindows
    } = getDefaultTaskTimeWindows({ isTwoPartTask, zone });

    const pickupTimeWindows = hasCustomPickupTimeWindows
        ? customPickupTimeWindows
        : defaultPickupTimeWindows;

    const deliveryTimeWindows = hasCustomDeliveryTimeWindows
        ? customDeliveryTimeWindows
        : defaultDeliveryTimeWindows;

    return {
        pickupTimeWindows,
        deliveryTimeWindows
    };
};

import axios from 'axios';
import {
    ApiCustomMapping,
    AxiosApiResponse,
    PaginationMetadata
} from '../types';
import { GetCustomMappingParams, PutCustomMappingParams } from './types';
import {
    getCustomMappingApiParamErrors,
    getCustomMappingParamUpdateErrors
} from './validate';

export default class CustomMappingApi {
    private static readonly path = '/custom_mapping';

    static get(
        params: GetCustomMappingParams = {}
    ): Promise<AxiosApiResponse<ApiCustomMapping, PaginationMetadata>> {
        const paramError = getCustomMappingApiParamErrors(params);

        if (paramError) {
            return Promise.reject(paramError);
        }

        return axios.get(this.path, { params });
    }

    static update(
        params: PutCustomMappingParams
    ): Promise<AxiosApiResponse<ApiCustomMapping, PaginationMetadata>> {
        const { customMapping, clientId } = params || {};
        const { defaults = {} } = customMapping || {};
        const paramError = getCustomMappingParamUpdateErrors({
            ...defaults,
            clientId
        });

        if (paramError) {
            return Promise.reject(paramError);
        }

        const payload = {
            client: clientId,
            updateBody: customMapping,
            configType: 'custom'
        };

        return axios.put(`${this.path}?client=${clientId}`, payload);
    }
}

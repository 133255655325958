import React, { useCallback } from 'react';
import classnames from 'classnames';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import ReactDatePicker from 'react-datepicker';

import { DatePickerHeader, UIElement } from '~/ui';

import './TimeRangeInput.scss';

interface TimeRangeInputProps extends UIElement {
    /**
     * optional className for styling
     */
    className?: string;
    /**
     * optional start time string in HH:mm format
     */
    startTime?: string | undefined;
    /**
     * optional end time string
     */
    endTime?: string | undefined;
    /**
     * optional ISO time format in HH:mm format
     */
    format?: string;
    /**
     * optional ISO time format to format return values
     * default H:mm i.e '8:30' or '22:45'
     */
    returnFormat?: string;
    /**
     * Handle change of start or end time
     */
    onChange: ({
        start,
        end
    }: {
        start: string | undefined;
        end: string | undefined;
    }) => void;
}

export const TimeRangeInput = ({
    startTime,
    endTime,
    format,
    returnFormat,
    onChange,
    className,
    ...extra
}: TimeRangeInputProps) => {
    const timeFormat = format || 'h:mm aa';
    const returnTimeFormat = returnFormat || 'H:mm';
    const rootClassName = 'time-range-input';

    const containerClassName = classnames(
        `${rootClassName}__container`,
        '_d-flex',
        '_jc-flex-start',
        className
    );

    const { t } = useTranslation(['time']);

    const setupDateTimeInput = (time: string) => {
        const [hour, minute] = time.split(':');
        return DateTime.fromObject({
            hour: Number(hour),
            minute: Number(minute),
            second: 0,
            millisecond: 0
        });
    };

    const startDateTime = startTime ? setupDateTimeInput(startTime) : undefined;
    const endDateTime = endTime ? setupDateTimeInput(endTime) : undefined;

    const handleStartChange = useCallback(
        (value: Date | null) => {
            const sDateTime = value ? DateTime.fromJSDate(value) : null;
            const eDateTime = endDateTime
                ? endDateTime.toFormat(returnTimeFormat)
                : undefined;
            if (sDateTime && sDateTime.isValid) {
                onChange({
                    start: sDateTime.toFormat(returnTimeFormat),
                    end: eDateTime
                });
            }
        },
        [endDateTime, returnTimeFormat, onChange]
    );

    const handleEndChange = useCallback(
        (value: Date | null) => {
            const eDateTime = value ? DateTime.fromJSDate(value) : null;
            const sDateTime = startDateTime
                ? startDateTime.toFormat(returnTimeFormat)
                : undefined;
            if (eDateTime && eDateTime.isValid) {
                onChange({
                    start: sDateTime,
                    end: eDateTime.toFormat(returnTimeFormat)
                });
            }
        },
        [startDateTime, returnTimeFormat, onChange]
    );

    return (
        <div
            className={containerClassName}
            data-testid={extra['data-testid'] || `${rootClassName}-container`}
        >
            <ReactDatePicker
                selected={startDateTime ? startDateTime.toJSDate() : null}
                onChange={(value) => {
                    handleStartChange(value);
                }}
                name="start-time"
                renderCustomHeader={DatePickerHeader}
                showTimeInput
                showTimeSelectOnly
                timeInputLabel={t('time:timeRangeInput.timeInputLabel')}
                dateFormat={timeFormat}
                shouldCloseOnSelect={false}
                className={`${rootClassName}__input ${rootClassName}__input--start-time`}
            />
            <ReactDatePicker
                selected={endDateTime ? endDateTime.toJSDate() : null}
                onChange={(value) => {
                    handleEndChange(value);
                }}
                renderCustomHeader={DatePickerHeader}
                showTimeInput
                name="end-time"
                showTimeSelectOnly
                timeInputLabel={t('time:timeRangeInput.timeInputLabel')}
                dateFormat={timeFormat}
                shouldCloseOnSelect={false}
                className={`${rootClassName}__input ${rootClassName}__input--end-time`}
            />
        </div>
    );
};

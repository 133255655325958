import React from 'react';
import classNames from 'classnames';
import { FlexLayout, Radio } from '~/ui/components';

import { RadioGroupProps } from './types';

import './RadioGroup.scss';

function RadioGroup<V extends string>({
    radioData,
    name,
    label,
    value,
    onChange,
    required,
    className,
    'data-testid': dataTestId,
    ...props
}: RadioGroupProps<V>): JSX.Element {
    const rootClassName = 'radio-group';
    const rootRadioGroupLabelClassName = `${rootClassName}__label`;
    const rootRadioInputGroupClassName = `${rootClassName}__radio-input-group`;

    const elementTestId = dataTestId || rootClassName;

    return (
        <div
            className={classNames(
                rootClassName,
                '_d-flex',
                '_fd-column',
                className
            )}
            data-testid={elementTestId}
        >
            {label && (
                <FlexLayout flexDirection="row" sx={{ columnGap: '0.4rem' }}>
                    <span
                        className={classNames(
                            rootRadioGroupLabelClassName,
                            '_d-flex',
                            '_ai-center',
                            props.radioGroupLabelClassName
                        )}
                        data-testid={props['radioGroupLabel-testid']}
                    >
                        {label}
                    </span>
                    {required && (
                        <span
                            data-testid={props['asterisk-testid']}
                            className={`${rootClassName}__asterisk`}
                        >
                            *
                        </span>
                    )}
                </FlexLayout>
            )}
            <FlexLayout
                className={classNames(
                    rootRadioInputGroupClassName,
                    props.radioInputGroupClassName
                )}
                data-testid={props['radioInputGroup-testid']}
            >
                {radioData.map(
                    ({
                        id: radioId,
                        label: radioLabel,
                        description: radioDescription,
                        value: radioValue,
                        disabled: isDisabled,
                        'data-testid': radioTestId
                    }) => {
                        const isChecked = value === radioValue;

                        const rootRadioLabelClassName = `${rootClassName}__radio-label`;
                        const rootRadioInputLabelClassName = `${rootClassName}__radio-input-label`;
                        const rootRadioLabelDescriptionClassName = `${rootClassName}__radio-label-description`;
                        const rootRadioInputClassName = `${rootClassName}__radio-input`;

                        const labelClassName = classNames(
                            rootRadioLabelClassName,
                            '_d-flex',
                            '_ai-center',
                            props.radioLabelClassName,
                            {
                                [`${rootRadioLabelClassName}--checked`]:
                                    isChecked,
                                [`${rootRadioLabelClassName}--disabled`]:
                                    isDisabled,
                                [`${props.radioLabelClassName}--checked`]:
                                    isChecked && props.radioLabelClassName,
                                [`${props.radioLabelClassName}--disabled`]:
                                    isDisabled && props.radioLabelClassName
                            }
                        );

                        const inputTextClassName = classNames(
                            `${rootClassName}__radio-input-text`,
                            '_fd-column',
                            {
                                [`${className}__radio-input-text`]: className
                            }
                        );

                        const inputLabelClassName = classNames(
                            rootRadioInputLabelClassName,
                            props.radioInputLabelClassName,
                            {
                                [`${rootRadioInputLabelClassName}--checked`]:
                                    isChecked,
                                [`${props.radioInputLabelClassName}--checked`]:
                                    isChecked && props.radioInputLabelClassName
                            }
                        );

                        const inputDescriptionClassName = classNames(
                            rootRadioLabelDescriptionClassName,
                            props.radioLabelDescriptionClassName
                        );

                        const inputRadioClassName = classNames(
                            rootRadioInputClassName,
                            props.radioInputClassName
                        );

                        return (
                            <label
                                className={labelClassName}
                                key={radioId}
                                data-testid={props['radioLabel-testid']}
                                htmlFor={radioId}
                            >
                                <Radio
                                    id={radioId}
                                    className={inputRadioClassName}
                                    name={name}
                                    value={radioValue}
                                    onChange={(e) =>
                                        onChange?.(e.target.value as V)
                                    }
                                    checked={isChecked}
                                    disabled={isDisabled}
                                    data-testid={
                                        radioTestId || props['radio-testid']
                                    }
                                />
                                <div className={inputTextClassName}>
                                    <span
                                        className={inputLabelClassName}
                                        data-testid={
                                            props['radioInputLabel-testid']
                                        }
                                    >
                                        {radioLabel}
                                    </span>
                                    {radioDescription && (
                                        <span
                                            className={
                                                inputDescriptionClassName
                                            }
                                            data-testid={
                                                props[
                                                    'radioLabelDescription-testid'
                                                ]
                                            }
                                        >
                                            {radioDescription}
                                        </span>
                                    )}
                                </div>
                            </label>
                        );
                    }
                )}
            </FlexLayout>
        </div>
    );
}

export default RadioGroup;

import { TimeWindow } from '~/api/types';
import { isEmpty, isPlainObject } from 'lodash';

export const isTimeWindowSet = (timeWindow: TimeWindow) => {
    if (!timeWindow || !isPlainObject(timeWindow) || isEmpty(timeWindow)) {
        return false;
    }
    const { start, end } = timeWindow;
    return Boolean(start && end);
};

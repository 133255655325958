import { configureStore } from '@reduxjs/toolkit';
import { CurriedGetDefaultMiddleware } from '@reduxjs/toolkit/dist/getDefaultMiddleware';
import {
    FLUSH,
    PAUSE,
    PERSIST,
    persistReducer,
    persistStore,
    PURGE,
    REGISTER,
    REHYDRATE
} from 'redux-persist';
import { createStorage, isReduxDevToolsEnabled } from '~/store/utils';
import { reducers } from './reducers';
import { rootPersistConfig } from './persistConfig';

export type ReduxState = ReturnType<typeof reducers>;

export const storage = createStorage();

const persistConfig = {
    ...rootPersistConfig,
    storage
};

const persistedReducer = persistReducer(persistConfig, reducers);

// updated middleware callback per [Redux Toolkit getDefaultMiddleware](https://redux-toolkit.js.org/api/getDefaultMiddleware)
/** @TODO Danny: Figure out how to type this correctly */
/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
const defaultMiddleware: any = (
    getDefaultMiddleware: CurriedGetDefaultMiddleware<ReduxState>
) => {
    return getDefaultMiddleware({
        serializableCheck: {
            ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
        }
    });
};

// enable devTools only for `local`, `stage`, and `sandbox` environments
const isDevToolsEnabled = isReduxDevToolsEnabled();

export const store = configureStore({
    devTools: isDevToolsEnabled,
    reducer: persistedReducer,
    middleware: defaultMiddleware
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

import CustomMappingApi from '~/api/CustomMappingApi';
import { GetCustomMappingParams } from '~/api/CustomMappingApi/types';
import { ApiCustomMapping } from '~/api/types';

import { selectMainClient } from '~/reducers/mainClientSlice';

import { REACT_QUERY_KEY, UseGetCustomMappingReactQueryOptions } from './types';

import { useFeatureFlag } from '~/hooks';
import { FeatureFlag } from '~/utils/feature-flags-utils';

export const useGetCustomMapping = () => {
    const mainClient = useSelector(selectMainClient);
    const { id: clientId } = mainClient || {};

    const isUpdateCustomMappingEnabled = useFeatureFlag(
        FeatureFlag.UPDATE_CUSTOM_MAPPING
    );

    const fetchCustomMapping = async (options: GetCustomMappingParams) => {
        const {
            data: { data }
        } = await CustomMappingApi.get(options);
        return data;
    };

    const apiOptions: GetCustomMappingParams = { client: clientId };

    const reactQueryOptions: UseGetCustomMappingReactQueryOptions = {
        enabled: Boolean(clientId) && isUpdateCustomMappingEnabled,
        retry: false
    };

    return useQuery<ApiCustomMapping, Error>(
        [REACT_QUERY_KEY, apiOptions],
        () => fetchCustomMapping(apiOptions),
        reactQueryOptions
    );
};

import React, { useContext } from 'react';

import { AddressTaskTypes } from '~/api/types';
import {
    AddressTabErrorState,
    AddressTabFields,
    ClientTask,
    ClientTaskErrorState,
    emptyClientTask,
    InventoryItemsTabErrorState,
    TaskInformationTabErrorState
} from '../utils/addTaskModalUtils';

export interface AddTaskModalContextProps {
    clientTask: Required<ClientTask>;
    setClientTask: React.Dispatch<React.SetStateAction<Required<ClientTask>>>;
    fillInAddress: (
        place: AddressTabFields,
        taskType: AddressTaskTypes
    ) => void;
    clearAddress: (taskType: AddressTaskTypes) => void;
    onStringFieldChange: <F extends MatchTypeKeys<ClientTask, string>>(
        field: F,
        value: Required<ClientTask>[F]
    ) => void;
    onInputChange: (
        field: MatchTypeKeys<ClientTask, string, true>,
        {
            target: { value }
        }: React.ChangeEvent<HTMLInputElement>
    ) => void;
    onOtherFieldChange: <
        F extends FilterTypeKeys<ClientTask, string | Array<unknown>>
    >(
        field: F,
        value: Required<ClientTask>[F]
    ) => void;
    onSizeByCompartmentChange: (
        value: Required<ClientTask>['sizeByCompartment']
    ) => void;
    onTimeWindowsChange: (
        field: 'deliveryTimeWindows' | 'pickupTimeWindows',
        value: Required<ClientTask>['deliveryTimeWindows' | 'pickupTimeWindows']
    ) => void;
    onInvoicesChange: (value: Required<ClientTask>['invoices']) => void;
    onInventoryItemsChange: (
        value: Required<ClientTask>['inventoryItems']
    ) => void;
    onNotesChange: (value: Required<ClientTask>['notes']) => void;
    errorState: ClientTaskErrorState;
    addressTabErrorState: AddressTabErrorState;
    taskInformationTabErrorState: TaskInformationTabErrorState;
    inventoryItemsTabErrorState: InventoryItemsTabErrorState;
}

export const AddTaskModalContext =
    React.createContext<AddTaskModalContextProps>({
        clientTask: emptyClientTask,
        setClientTask: () => undefined,
        fillInAddress: () => undefined,
        clearAddress: () => undefined,
        onStringFieldChange: () => undefined,
        onSizeByCompartmentChange: () => undefined,
        onInputChange: () => undefined,
        onOtherFieldChange: () => undefined,
        onTimeWindowsChange: () => undefined,
        onInvoicesChange: () => undefined,
        onInventoryItemsChange: () => undefined,
        onNotesChange: () => undefined,
        errorState: {},
        addressTabErrorState: {},
        taskInformationTabErrorState: {},
        inventoryItemsTabErrorState: {}
    });

export const useAddTaskModalContext = (): AddTaskModalContextProps => {
    const addTaskModalContext = useContext(AddTaskModalContext);
    if (!addTaskModalContext) {
        throw new Error(
            'useAddTaskModalContext must be used within an AddTaskModalContext Provider'
        );
    }
    return addTaskModalContext;
};

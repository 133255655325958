import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { useFeatureFlag } from '~/hooks';
import { FeatureFlag } from '~/utils/feature-flags-utils';

import { RadioInputData } from '~/ui/components/RadioGroup';
import { RadioGroup } from '~/ui';
import { AssignmentTypes, DuplicateTaskModalFormProps } from '../types';
import { DuplicateTaskModalCollapsible } from '../DuplicateTaskModalCollapsible';

import './DuplicateTaskModalChooseAssignment.scss';

export const DuplicateTaskModalChooseAssignment = () => {
    const rootClassName = 'duplicate-task-modal-choose-assignment';
    const { t } = useTranslation('duplicateTaskModal');

    const isEnabledSplitTaskAssignToDriver = useFeatureFlag(
        FeatureFlag.SPLIT_TASK_ASSIGN_TO_DRIVER
    );

    const { values, setFieldValue } =
        useFormikContext<DuplicateTaskModalFormProps>();

    const setSelectedActionType = useCallback(
        (assignmentType: AssignmentTypes) => {
            setFieldValue('assignmentType', assignmentType);
        },
        [setFieldValue]
    );

    const radioData = useMemo(() => {
        const rootTestId =
            'duplicate-task-modal-choose-assignment__radio-input';
        const data: RadioInputData<AssignmentTypes>[] = [
            {
                id: `${rootClassName}-${AssignmentTypes.CREATE_TASK}`,
                label: t('assignment.createTask'),
                value: AssignmentTypes.CREATE_TASK,
                'data-testid': `${rootTestId}--${AssignmentTypes.CREATE_TASK}`
            }
        ];

        if (isEnabledSplitTaskAssignToDriver) {
            data.push({
                id: `${rootClassName}-${AssignmentTypes.ASSIGN_TASK_TO_DRIVER}`,
                label: t('assignment.assignTaskToDriver'),
                value: AssignmentTypes.ASSIGN_TASK_TO_DRIVER,
                'data-testid': `${rootTestId}--${AssignmentTypes.ASSIGN_TASK_TO_DRIVER}`,
                disabled: true
            });
        }

        return data;
    }, [isEnabledSplitTaskAssignToDriver, t]);

    return (
        <DuplicateTaskModalCollapsible
            title={t('assignment.title')}
            subtitle={t(`assignment.${values.assignmentType}`)}
        >
            <RadioGroup
                radioData={radioData}
                onChange={setSelectedActionType}
                value={values.assignmentType}
                name="assignmentType"
                className={rootClassName}
                data-testid={rootClassName}
                radioLabelClassName={`${rootClassName}__radio-label`}
                radioLabel-testid={`${rootClassName}__radio-label`}
                radioLabelDescriptionClassName={`${rootClassName}__radio-label-description`}
                radioInputGroupClassName={`${rootClassName}__radio-input-group`}
                radioInputGroup-testid={`${rootClassName}__radio-input-group`}
                radioInputClassName={`${rootClassName}__radio-input`}
                radio-testid={`${rootClassName}__radio-input`}
                radioGroupLabelClassName={`${rootClassName}__radio-group-label`}
                radioGroupLabel-testid={`${rootClassName}__radio-group-label`}
                radioInputLabelClassName={`${rootClassName}__radio-input-label`}
                radioInputLabel-testid={`${rootClassName}__radio-input-label`}
            />
        </DuplicateTaskModalCollapsible>
    );
};

import { useMemo } from 'react';
import { StackMarkerTasks } from '~/ui/components/StackPinMarker/types';
import { useMapUtils } from '~/hooks';
import { ApiLiveStop } from '~/api/types';

export const useStackMarkerHasCompleted = (tasks: StackMarkerTasks) => {
    const { isPlanRouteMode } = useMapUtils();

    return useMemo(() => {
        if (isPlanRouteMode) {
            return false;
        }
        return tasks.some((stop) => (stop as ApiLiveStop).isCompleted);
    }, [isPlanRouteMode, tasks]);
};

import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { intersection } from 'lodash';

import { selectSelectedMapStops } from '~/reducers/selectedMapStopsSlice';
import { useMapUtils } from '~/hooks';

import { PlanStop } from '~/data-classes';
import { markerUtils } from '~/utils/map';
import { StackMarkerTasks } from '~/ui/components/StackPinMarker/types';
import { ApiLiveStop } from '~/api/types';

export const useIsStackMarkerSelected = (tasks: StackMarkerTasks) => {
    const { isPlanRouteMode } = useMapUtils();
    const selectedMapStops = useSelector(selectSelectedMapStops);

    return useMemo(() => {
        let taskIds = [];
        if (isPlanRouteMode) {
            taskIds = tasks.map((taskData) => {
                const { clientRouteTaskId } = taskData as PlanStop;
                if (!clientRouteTaskId) {
                    return markerUtils.getTaskIdForSelection(taskData);
                }
                return clientRouteTaskId;
            });
        } else {
            taskIds = tasks.map((taskData) => {
                const { stopNumber } = taskData as ApiLiveStop;
                if (stopNumber) {
                    return taskData.id;
                }
                return markerUtils.getTaskIdForSelection(taskData);
            });
        }
        return Boolean(intersection(taskIds, selectedMapStops).length);
    }, [tasks, isPlanRouteMode, selectedMapStops]);
};

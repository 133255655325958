import {
    CustomMappingDefaultProps,
    CustomMappingTypes,
    TimeWindow
} from '~/api/types';
import { formatServiceTimeToDuration } from '~/utils/time-utils/time-utils';

type FormattedValue = TimeWindow | string | undefined;

type FormattedValues = Record<string, FormattedValue>;

const formattingMap = {
    [CustomMappingTypes.PROPS_DELIVERY_WINDOW]: (
        window: TimeWindow | undefined
    ) => window,
    [CustomMappingTypes.PROPS_PICKUP_WINDOW]: (
        window: TimeWindow | undefined
    ) => window,
    [CustomMappingTypes.DELIVERY_SERVICE_TIME]: (time: string | undefined) =>
        formatServiceTimeToDuration(time),
    [CustomMappingTypes.PICKUP_SERVICE_TIME]: (time: string | undefined) =>
        formatServiceTimeToDuration(time)
};

export const getPayload = (values: CustomMappingDefaultProps) => {
    return Object.keys(values).reduce(
        (aggregator: FormattedValues, key: string) => {
            const value = values[key as keyof CustomMappingDefaultProps];

            const formatter = formattingMap[
                key as keyof typeof formattingMap
            ] as (val: FormattedValue) => FormattedValue;

            aggregator[key] = formatter(value);
            return aggregator;
        },
        {}
    );
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import { ModalProps, ModalSize } from '~/ui/components/Modal/types';
import { DuplicateTaskModalHeader } from './DuplicateTaskModalHeader';
import { DuplicateTaskModalFooter } from './DuplicateTaskModalFooter';
import { DuplicateTaskModalContextProvider } from './DuplicateTaskModalContext';
import { DuplicateTaskModalChooseAssignment } from './DuplicateTaskModalChooseAssignment';
import { DuplicateTaskModalChooseItems } from './DuplicateTaskModalChooseItems';
import { DuplicateTaskModalActions } from './DuplicateTaskModalActions';

import './DuplicateTaskModal.scss';

export interface DuplicateTaskModalProps {
    taskId: string | null;
    Modal: (props: ModalProps) => JSX.Element;
    onClose: () => void;
}

export const DuplicateTaskModal = ({
    Modal,
    onClose,
    taskId
}: DuplicateTaskModalProps) => {
    const rootClassName = 'duplicate-task-modal';
    const { t } = useTranslation('duplicateTaskModal');

    return (
        <Modal
            hasCloseButton
            variant={ModalSize.SMALL}
            title={t('title')}
            className={rootClassName}
        >
            <DuplicateTaskModalContextProvider
                taskId={taskId}
                onClose={onClose}
            >
                <>
                    <div
                        className={`${rootClassName}__content _d-flex _fd-column`}
                    >
                        <DuplicateTaskModalHeader />
                        <DuplicateTaskModalChooseAssignment />
                        <DuplicateTaskModalActions />
                        <DuplicateTaskModalChooseItems />
                    </div>
                    <DuplicateTaskModalFooter />
                </>
            </DuplicateTaskModalContextProvider>
        </Modal>
    );
};

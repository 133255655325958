import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { TaskTypes } from '~/api/types';
import { RadioInputData } from '~/ui/components/RadioGroup';
import { ChangeTaskStatusModalSelectedTaskProps } from './types';

import { RadioGroup } from '~/ui';
import { PreviewStopCard } from '~/ui/components/PreviewStopCard';
import { ChangeTaskStatusModalAlert } from '../ChangeTaskStatusModalAlert';
import { getChangeTaskStatusModalPreselectedTask } from './utils';

import './ChangeTaskStatusModalSelectedTask.scss';

export const ChangeTaskStatusModalSelectedTask = ({
    stopData,
    selectedStopId,
    setSelectedStopId
}: ChangeTaskStatusModalSelectedTaskProps) => {
    const ROOT_CLASS_NAME = 'change-task-status-modal-selected-task';

    const { t } = useTranslation('changeTaskStatus');

    const { isTwoPart, taskTypeRadioData, hasStopData } = useMemo(() => {
        const { pickup: pickupStopData, delivery: deliveryStopData } =
            stopData || {};
        const isTwoPartStop = Boolean(pickupStopData && deliveryStopData);

        const radioData: RadioInputData<string>[] = [];

        if (pickupStopData) {
            const isPickupDisabled =
                pickupStopData.isCompleted || pickupStopData.isCanceled;
            radioData.push({
                id: `task-type__${TaskTypes.PICKUP}--${pickupStopData.id}`,
                'data-testid': `${ROOT_CLASS_NAME}__${TaskTypes.PICKUP}-input`,
                label: t('selectTask.pickup'),
                value: pickupStopData.id,
                disabled: isPickupDisabled
            });
        }

        if (deliveryStopData) {
            radioData.push({
                id: `task-type__${TaskTypes.DELIVERY}--${deliveryStopData.id}`,
                'data-testid': `${ROOT_CLASS_NAME}__${TaskTypes.DELIVERY}-input`,
                label: t('selectTask.delivery'),
                value: deliveryStopData.id,
                disabled: false
            });
        }

        return {
            isTwoPart: isTwoPartStop,
            taskTypeRadioData: radioData,
            hasStopData: radioData.length > 0
        };
    }, [stopData, t]);

    const { alert, preselectedStopId } = useMemo(() => {
        return getChangeTaskStatusModalPreselectedTask(
            stopData,
            selectedStopId
        );
    }, [stopData, selectedStopId]);

    useEffect(() => {
        if (!alert || !preselectedStopId) return;
        setSelectedStopId(preselectedStopId);
    }, [alert, preselectedStopId, setSelectedStopId]);

    if (!hasStopData) return null;

    return (
        <>
            {stopData && (
                <div
                    className={`${ROOT_CLASS_NAME} ${ROOT_CLASS_NAME}__preview-cards`}
                    data-testid={`${ROOT_CLASS_NAME}__preview-cards`}
                >
                    <PreviewStopCard
                        stopData={stopData}
                        selectedStopId={selectedStopId}
                    />
                </div>
            )}
            {isTwoPart && (
                <div
                    className={`${ROOT_CLASS_NAME} ${ROOT_CLASS_NAME}__select-type _fd-column`}
                    data-testid={`${ROOT_CLASS_NAME}__select-type`}
                >
                    <RadioGroup
                        radioData={taskTypeRadioData}
                        onChange={setSelectedStopId}
                        value={selectedStopId || ''}
                        name="changeTaskStatus"
                        label="Choose desired task section you want to update"
                        className={`${ROOT_CLASS_NAME}__radio-group`}
                        data-testid={`${ROOT_CLASS_NAME}__radio-group`}
                        radioLabelClassName={`${ROOT_CLASS_NAME}__radio-label`}
                        radioLabel-testid={`${ROOT_CLASS_NAME}__radio-label`}
                        radioInputGroupClassName={`${ROOT_CLASS_NAME}__radio-input-group`}
                        radioInputGroup-testid={`${ROOT_CLASS_NAME}__radio-input-group`}
                        radioInputClassName={`${ROOT_CLASS_NAME}__radio-input`}
                        radio-testid={`${ROOT_CLASS_NAME}__radio-input`}
                        radioGroupLabelClassName={`${ROOT_CLASS_NAME}__radio-group-label`}
                        radioGroupLabel-testid={`${ROOT_CLASS_NAME}__radio-group-label`}
                        radioInputLabelClassName={`${ROOT_CLASS_NAME}__radio-input-label`}
                        radioInputLabel-testid={`${ROOT_CLASS_NAME}__radio-input-label`}
                    />
                    {alert && (
                        <ChangeTaskStatusModalAlert>
                            {t(alert)}
                        </ChangeTaskStatusModalAlert>
                    )}
                </div>
            )}
        </>
    );
};

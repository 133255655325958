import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { useClickOutside } from '~/ui/hooks';
import { StackPinMarkerPopup } from '~/ui';
import { StopMarkerList } from '~/ui/components/StackPinMarkerPopup/StopMarkerList';
import { useStackMarkerStyle } from './useStackMarkerStyle';
import { useIsStackMarkerSelected } from './useIsStackMarkerSelected';

import { StackPinMarkerProps } from './types';

import './StackPinMarker.scss';

export const StackPinMarker = ({
    numClustered,
    tasks,
    emittedEventHandler = () => {},
    onDemandDispatchMarkerEventHandler = () => {},
    isShowClickAllButton
}: StackPinMarkerProps) => {
    const { t } = useTranslation(['common']);
    const ref = useRef<HTMLSpanElement>(null);
    const [showStackPinPopup, setShowStackPinPopup] = useState(false);

    const isStackMarkerSelected = useIsStackMarkerSelected(tasks);
    const { markerStyle, shouldShowGradient } = useStackMarkerStyle(tasks);

    const handleOnClick = () => {
        setShowStackPinPopup(!showStackPinPopup);
    };

    useClickOutside(ref, showStackPinPopup ? handleOnClick : null);

    const getClassName = () => {
        const boxClassName = 'stopmarker-body';

        const conditionalClasses = {
            'stopmarker-body--selected': showStackPinPopup,
            'stopmarker-body--highlighted': isStackMarkerSelected
        };
        return classNames(boxClassName, conditionalClasses);
    };

    const getGradientBackgroundClassName = () => {
        return classNames('stopmarker-circle', {
            'gradient-background': shouldShowGradient
        });
    };

    return (
        <span
            ref={ref}
            className="stack-pin-marker"
            data-testid="stack-pin-marker"
        >
            {showStackPinPopup && (
                <StackPinMarkerPopup tasksCount={tasks.length}>
                    <StopMarkerList
                        tasks={tasks}
                        emittedEventHandler={emittedEventHandler}
                        onDemandDispatchMarkerEventHandler={
                            onDemandDispatchMarkerEventHandler
                        }
                        isShowClickAllButton={isShowClickAllButton}
                    />
                </StackPinMarkerPopup>
            )}
            <button
                type="button"
                className="stopmarker unassigned-stack-pin"
                data-testid="stop-marker"
                onClick={handleOnClick}
            >
                <div
                    className={getClassName()}
                    data-testid="default-stop-marker"
                >
                    <span
                        className={getGradientBackgroundClassName()}
                        data-testid="stop-marker-circle"
                        style={markerStyle}
                    />
                    <span
                        className="stopmarker-circle"
                        data-testid="stop-marker-circle"
                        style={markerStyle}
                    />
                    <span
                        className="stack-cluster-count"
                        data-testid="stack-cluster-count"
                    >
                        {t(
                            numClustered > 100
                                ? 'stackStopsPopup.moreClusteredStopsCount'
                                : 'stackStopsPopup.clusteredStopsCount',
                            {
                                count: numClustered
                            }
                        )}
                    </span>
                </div>
            </button>
        </span>
    );
};

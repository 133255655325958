import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ReactDatePicker from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import Switch from 'react-switch';

import { AddTaskTypes, AddressTaskTypes, TaskTypes } from '~/api/types/ApiTask';
import { useFeatureFlag, useIntermodalFeatures } from '~/hooks';
import {
    LabelInput,
    RadioGroup,
    DatePickerHeader,
    FlexLayout,
    theme
} from '~/ui';
import constants from '~/utils/constants';
import { RadioInputData } from '~/ui/components/RadioGroup';
import DropDownMenu from '~/ui/components/DropDownMenu/DropDownMenu';
import DropDownMenuItem from '~/ui/components/DropDownMenu/DropDownMenuItem';

import { useAddTaskModalContext } from '../AddTaskModalContext';
import {
    className as addTaskClassName,
    clientTaskRequiredFieldMap
} from '../utils/addTaskModalUtils';
import { FeatureFlag } from '~/utils/feature-flags-utils';

import { labelInputTestIdSuffix } from '~/ui/components/LabelInput/LabelInput';
import {
    selectIsEquipmentDepotPairing,
    selectIsOpenPairEquipmentControlPopup
} from '~/reducers/mapDrawerSettingsSlice';
import { AddressInput } from '../AddressInput';
import {
    selectSelectedEquipment,
    selectSelectedTaskType,
    setSelectedTaskType
} from '~/reducers/addTaskSlice';

import './AddressTabContent.scss';

const className = `${addTaskClassName}-addressTab`;
export const datePickerId = `${className}-datepicker`;

export const testId = 'addressTab_testId';
export const addressAptWrapperTestId = 'addressTab_addressAptWrapperTestId';
export const addressAptTestId = `${addressAptWrapperTestId}-${labelInputTestIdSuffix}`;
export const addressCityWrapperTestId = 'addressTab_addressCityWrapperTestId';
export const addressCityTestId = `${addressCityWrapperTestId}-${labelInputTestIdSuffix}`;
export const addressStateWrapperTestId = 'addressTab_addressStateWrapperTestId';
export const addressStateTestId = `${addressStateWrapperTestId}-${labelInputTestIdSuffix}`;
export const addressStreetWrapperTestId =
    'addressTab_addressStreetWrapperTestId';
export const addressStreetTestId = `${addressStreetWrapperTestId}-${labelInputTestIdSuffix}`;
export const addressZipCodeWrapperTestId =
    'addressTab_addressZipCodeWrapperTestId';
export const addressZipCodeTestId = `${addressZipCodeWrapperTestId}-${labelInputTestIdSuffix}`;
export const customerIdWrapperTestId = 'addressTab_customerIdWrapperTestId';
export const customerIdTestId = `${customerIdWrapperTestId}-${labelInputTestIdSuffix}`;
export const customerNameWrapperTestId = 'addressTab_customerNameWrapperTestId';
export const customerNameTestId = `${customerNameWrapperTestId}-${labelInputTestIdSuffix}`;
export const latitudeWrapperTestId = 'addressTab_latitudeWrapperTestId';
export const latitudeTestId = `${latitudeWrapperTestId}-${labelInputTestIdSuffix}`;
export const longitudeWrapperTestId = 'addressTab_longitudeWrapperTestId';
export const longitudeTestId = `${longitudeWrapperTestId}-${labelInputTestIdSuffix}`;
export const taskTypeRadioGroupTestId = 'addressTab_taskTypeRadioGroupTestId';
export const taskTypeRadioTestId = 'addressTab_taskTypeRadioTestId';
export const datePickerTestId = 'addressTab_datepickerTestId';
export const externalTaskTypeWrapperTestId =
    'addressTab_externalTaskTypeWrapperTestId';
export const externalTaskTypeTestId = `${externalTaskTypeWrapperTestId}-${labelInputTestIdSuffix}`;

export const AddressTabContent: React.FC = (): JSX.Element => {
    const { t } = useTranslation(['addTask']);
    const externalTaskTypeDropDownMenuRef = useRef<HTMLDivElement>(null);

    const dispatch = useDispatch();
    const selectedTaskType = useSelector(selectSelectedTaskType);
    const { enableEditShowToDriver } = useIntermodalFeatures();

    const [
        isExternalTaskTypeDropDownMenuOpen,
        setIsExternalTaskTypeDropDownMenuOpen
    ] = useState(false);
    const toggleExternalTaskTypeDropDown = useCallback(() => {
        setIsExternalTaskTypeDropDownMenuOpen(
            (isDropDownOpen) => !isDropDownOpen
        );
    }, []);
    const closeExternalTaskTypeDropDown = useCallback(() => {
        setIsExternalTaskTypeDropDownMenuOpen(false);
    }, []);
    const isPredefinedExternalTaskTypeEnabled = useFeatureFlag(
        FeatureFlag.PREDEFINED_EXTERNAL_TASK_TYPES
    );
    const selectedEquipment = useSelector(selectSelectedEquipment);

    const {
        clientTask,
        onStringFieldChange,
        onOtherFieldChange,
        onInputChange
    } = useAddTaskModalContext();

    const isEquipmentPairing = useSelector(
        selectIsOpenPairEquipmentControlPopup
    );
    const isEquipmentDepotPairing = useSelector(selectIsEquipmentDepotPairing);

    const onTaskDateChange = useCallback(
        (date: Date) => {
            onOtherFieldChange('date', date);
        },
        [onOtherFieldChange]
    );

    const onTaskTaskTypeChange = useCallback(
        (taskType: TaskTypes) => {
            onStringFieldChange('taskType', taskType);
            if (selectedTaskType !== taskType)
                dispatch(setSelectedTaskType(taskType));
        },
        [dispatch, onStringFieldChange, selectedTaskType]
    );

    const onShowToDriverSwitchChange = useCallback(
        (check: boolean) => {
            onOtherFieldChange('showToDriver', check);
        },
        [onOtherFieldChange]
    );

    const onExternalTaskTypeChangeText = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            onInputChange('externalTaskType', e);
        },
        [onInputChange]
    );

    const onExternalTaskTypeChangeSelect = useCallback(
        (externalTaskType: string) => {
            onStringFieldChange('externalTaskType', externalTaskType);

            function isValidExternalTaskType(
                type: string
            ): type is keyof typeof constants.externalTaskTypeNames {
                return Object.keys(constants.externalTaskTypeNames).includes(
                    type
                );
            }

            const serviceTimeMins =
                (isValidExternalTaskType(externalTaskType) &&
                    constants.presetServiceTimes[externalTaskType]) ||
                '0';

            onStringFieldChange('deliveryServiceTimeMins', serviceTimeMins);
            onStringFieldChange('pickupServiceTimeMins', serviceTimeMins);

            toggleExternalTaskTypeDropDown();
        },
        [onStringFieldChange, toggleExternalTaskTypeDropDown]
    );

    const onLongitudeChange = useCallback(
        (value: string) => {
            const isTwoPart = clientTask.taskType === TaskTypes.TWOPART;
            if (isTwoPart) {
                onStringFieldChange('pickupLongitude', value);
                return;
            }

            onStringFieldChange(
                `${
                    clientTask.taskType as unknown as AddressTaskTypes
                }Longitude`,
                value
            );
        },
        [onStringFieldChange, clientTask.taskType]
    );

    const onLatitudeChange = useCallback(
        (value: string) => {
            const isTwoPart = clientTask.taskType === TaskTypes.TWOPART;
            if (isTwoPart) {
                onStringFieldChange('pickupLatitude', value);
                return;
            }

            onStringFieldChange(
                `${clientTask.taskType as unknown as AddressTaskTypes}Latitude`,
                value
            );
        },
        [onStringFieldChange, clientTask.taskType]
    );

    useEffect(() => {
        if (!selectedEquipment) return;

        if (isEquipmentPairing || isEquipmentDepotPairing)
            onTaskTaskTypeChange(TaskTypes.PICKUP);

        if (selectedEquipment.lat)
            onLatitudeChange(selectedEquipment.lat.toString());

        if (selectedEquipment.lng)
            onLongitudeChange(selectedEquipment.lng.toString());
    }, [
        selectedEquipment,
        isEquipmentPairing,
        isEquipmentDepotPairing,
        onTaskTaskTypeChange,
        onLatitudeChange,
        onLongitudeChange
    ]);

    const taskTypeRadioData: RadioInputData<TaskTypes>[] = [
        {
            id: `task-type--${TaskTypes.DELIVERY}`,
            label: t('fields.taskType.fields.delivery.label'),
            value: TaskTypes.DELIVERY,
            disabled: isEquipmentPairing || isEquipmentDepotPairing
        },
        {
            id: `task-type--${TaskTypes.PICKUP}`,
            label: t('fields.taskType.fields.pickup.label'),
            value: TaskTypes.PICKUP
        },
        {
            id: `task-type--${TaskTypes.TWOPART}`,
            label: t('fields.taskType.fields.twoPart.label'),
            value: TaskTypes.TWOPART,
            disabled: isEquipmentPairing || isEquipmentDepotPairing
        }
    ];

    const isTwoPartTask = clientTask.taskType === TaskTypes.TWOPART;

    const isOpenPairEquipmentControlPopupop = useSelector(
        selectIsOpenPairEquipmentControlPopup
    );

    const taskType =
        isEquipmentPairing || isEquipmentDepotPairing
            ? AddTaskTypes.PICKUP
            : (clientTask.taskType as unknown as AddTaskTypes);

    const predefinedExternalTaskTypes =
        constants.externalTaskTypePredefinedData[taskType];

    return (
        <div className="address-tab-content" data-testid={testId}>
            <div className="address-tab-content__date-container _fd-column _w-100">
                <div className="address-tab-content__date-label _d-flex">
                    <span className="_header-4">{t('fields.date.label')}</span>
                    {clientTaskRequiredFieldMap(selectedTaskType).date && (
                        <span className="label-input_asterisk">*</span>
                    )}
                </div>
                <ReactDatePicker
                    id={datePickerId}
                    calendarClassName="address-tab-content__datepicker"
                    selected={clientTask.date}
                    onChange={onTaskDateChange}
                    renderCustomHeader={DatePickerHeader}
                />
            </div>
            <RadioGroup
                radioData={taskTypeRadioData}
                onChange={onTaskTaskTypeChange}
                value={
                    isEquipmentPairing || isEquipmentDepotPairing
                        ? TaskTypes.PICKUP
                        : clientTask.taskType
                }
                name="taskType"
                label={t('fields.taskType.label')}
                required={clientTaskRequiredFieldMap(selectedTaskType).taskType}
                className="address-tab-content__radio-group-wrapper _w-100"
                radioInputGroupClassName="address-tab-content__radio-group"
                radioInputClassName="address-tab-content__radio-input"
                data-testid={taskTypeRadioGroupTestId}
                radio-testid={taskTypeRadioTestId}
            />
            {enableEditShowToDriver && (
                <div className="address-tab-content__show-to-driver-switch">
                    <Switch
                        checked={Boolean(clientTask.showToDriver)}
                        data-testid="address-tab-content__show-to-driver-switch-button"
                        onChange={onShowToDriverSwitchChange}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        handleDiameter={20}
                        offColor={theme.colors['galaxy-200']}
                        onColor={theme.colors.ocean}
                        height={24}
                        width={40}
                    />
                    <span>{t('showToDriver')}</span>
                </div>
            )}
            {isPredefinedExternalTaskTypeEnabled &&
            !isOpenPairEquipmentControlPopupop ? (
                <FlexLayout
                    className="address-tab-content__external-task-type _mt-4"
                    flexDirection="column"
                    sx={{ rowGap: '1.6rem' }}
                >
                    <label className="external-task-type-dropdown-label">
                        {t('fields.externalTaskType.label')}
                    </label>
                    <DropDownMenu
                        title={{
                            name: clientTask.externalTaskType
                        }}
                        placeholder={t('fields.externalTaskType.label')}
                        styles={{
                            innerContainer:
                                'dropdown-flex-space-between dropdown_outlined',
                            menuTitle: 'dropdown-selection',
                            menuTitleText: 'dropdown-title-text',
                            dropdownToggleColor: 'var(--color-galaxy-800)'
                        }}
                        ref={externalTaskTypeDropDownMenuRef}
                        isOpen={isExternalTaskTypeDropDownMenuOpen}
                        toggle={toggleExternalTaskTypeDropDown}
                        close={closeExternalTaskTypeDropDown}
                        icon="chevronDown"
                        position="down"
                        data-testid={`${externalTaskTypeWrapperTestId}-Dropdown`}
                    >
                        {predefinedExternalTaskTypes.map((text: string) => (
                            <DropDownMenuItem
                                key={text}
                                menuItem={{
                                    text,
                                    onClick: () =>
                                        onExternalTaskTypeChangeSelect(text)
                                }}
                                data-testid={text}
                            />
                        ))}
                    </DropDownMenu>
                </FlexLayout>
            ) : (
                <LabelInput
                    className="address-tab-content__external-task-type _mt-4"
                    label={t('fields.externalTaskType.label')}
                    placeholder={t('fields.externalTaskType.placeholder')}
                    value={
                        isOpenPairEquipmentControlPopupop
                            ? constants.externalTaskTypeNames['Empty pickup']
                            : clientTask.externalTaskType
                    }
                    disabled={isOpenPairEquipmentControlPopupop}
                    onChange={onExternalTaskTypeChangeText}
                    required={
                        clientTaskRequiredFieldMap(selectedTaskType)
                            .externalTaskType
                    }
                    data-testid={externalTaskTypeWrapperTestId}
                />
            )}
            <AddressInput
                onLongitudeChange={onLongitudeChange}
                onLatitudeChange={onLatitudeChange}
                taskType={
                    isTwoPartTask
                        ? AddressTaskTypes.PICKUP
                        : (selectedTaskType as unknown as AddressTaskTypes)
                }
            />
            {isTwoPartTask && (
                <AddressInput
                    onLongitudeChange={onLongitudeChange}
                    onLatitudeChange={onLatitudeChange}
                    taskType={AddressTaskTypes.DELIVERY}
                />
            )}
        </div>
    );
};

import { isPlainObject } from 'lodash';
import { DateTime, Zone } from 'luxon';
import { TimeWindow } from '~/api/types';
import { setLocalTimeToZone } from '~/utils/time-utils';

const getISOTimeFrom24HourTime = (time = '') => {
    const [hours, minutes] = time.split(':');
    const numericHour = Number(hours);
    const numericMinute = Number(minutes);

    if (Number.isNaN(numericHour) || Number.isNaN(numericMinute)) return '';

    return DateTime.fromObject({
        hour: numericHour,
        minute: numericMinute
    }).toFormat('HH:mm');
};

export const getJsDateTimeWindows = (
    timeWindow: Partial<TimeWindow>,
    zone?: string | Zone
): TimeWindow<Date> | undefined => {
    if (!isPlainObject(timeWindow)) return;
    const { start, end } = timeWindow;

    const startTime = getISOTimeFrom24HourTime(start);
    const endTime = getISOTimeFrom24HourTime(end);

    const startDateTime = setLocalTimeToZone(startTime, zone);
    const endDateTime = setLocalTimeToZone(endTime, zone);

    if (!startDateTime.isValid || !endDateTime.isValid) return;

    // adjust for 24-hour operations
    const isEndTimeEarlier = endDateTime <= startDateTime;
    const finalEndDateTime = isEndTimeEarlier
        ? endDateTime.plus({ days: 1 })
        : endDateTime;

    const startJsDate = startDateTime.toJSDate();
    const endJsDate = finalEndDateTime.toJSDate();

    return {
        start: startJsDate,
        end: endJsDate
    };
};

import { useCallback, useMemo } from 'react';
import { isEmpty, isPlainObject } from 'lodash';

import { useClientTimezone } from '~/hooks';
import { useGetCustomMapping } from '~/hooks/useGetCustomMapping';

import { getJsDateTimeWindows } from './utils';

import { getDurationInMinutes } from '~/utils/time-utils';

import {
    TimeWindow,
    CustomMappingTypes,
    CustomMappingDefaultProps
} from '~/api/types';
import { ClientTask } from '../utils/addTaskModalUtils';

export const useClientTaskCustomMapping = (): ClientTask => {
    const { data: customMapping } = useGetCustomMapping();
    const { enabledTimezone: zone } = useClientTimezone();

    const defaultCustomMapping = useMemo<CustomMappingDefaultProps>(() => {
        // it is possible for the API to return a `defaults` value that is not a plain object
        const isValidCustomMapping =
            customMapping?.defaults && isPlainObject(customMapping.defaults);

        return isValidCustomMapping
            ? (customMapping.defaults as CustomMappingDefaultProps)
            : {};
    }, [customMapping]);

    /**
     * Adjusts the incoming time window so that the local times stay the same, but in the target (`client`) timezone.
     */
    const getAdjustedTimeWindows = useCallback(
        (timeWindow: Partial<TimeWindow>) => {
            const timeWindows = [timeWindow];
            const customWindow = timeWindows.reduce<TimeWindow<Date>[]>(
                (acc, tw) => {
                    const updated = getJsDateTimeWindows(tw, zone);
                    if (updated) acc.push(updated);
                    return acc;
                },
                []
            );

            return customWindow.length > 0 ? customWindow : undefined;
        },
        [zone]
    );

    const deliveryTimeWindows = useMemo(() => {
        const {
            [CustomMappingTypes.PROPS_DELIVERY_WINDOW]: customTimeWindow = {}
        } = defaultCustomMapping;
        return getAdjustedTimeWindows(customTimeWindow);
    }, [defaultCustomMapping, getAdjustedTimeWindows]);

    const pickupTimeWindows = useMemo(() => {
        const {
            [CustomMappingTypes.PROPS_PICKUP_WINDOW]: customTimeWindow = {}
        } = defaultCustomMapping;
        return getAdjustedTimeWindows(customTimeWindow);
    }, [defaultCustomMapping, getAdjustedTimeWindows]);

    const deliveryServiceTimeMins = useMemo(() => {
        const {
            [CustomMappingTypes.DELIVERY_SERVICE_TIME]: customServiceTime = ''
        } = defaultCustomMapping;
        return getDurationInMinutes(customServiceTime)?.toString();
    }, [defaultCustomMapping]);

    const pickupServiceTimeMins = useMemo(() => {
        const {
            [CustomMappingTypes.PICKUP_SERVICE_TIME]: customServiceTime = ''
        } = defaultCustomMapping;
        return getDurationInMinutes(customServiceTime)?.toString();
    }, [defaultCustomMapping]);

    return useMemo(() => {
        const customProps: ClientTask = {
            deliveryTimeWindows,
            pickupTimeWindows,
            deliveryServiceTimeMins,
            pickupServiceTimeMins
        };

        // remove props with `undefined` values
        const customPropsWithValues = Object.entries(
            customProps
        ).reduce<ClientTask>((acc, customProp) => {
            const [key, value] = customProp;
            if (!isEmpty(value)) acc[key as keyof ClientTask] = value;
            return acc;
        }, {});

        return customPropsWithValues;
    }, [
        deliveryTimeWindows,
        pickupTimeWindows,
        deliveryServiceTimeMins,
        pickupServiceTimeMins
    ]);
};

import React from 'react';
import classnames from 'classnames';

import './TimeRangeInput.scss';

interface TimeRangeInputLabelProps {
    children: React.ReactNode;
    labelFor?: string;
    className?: string;
}

export const TimeRangeInputLabel = ({
    children,
    labelFor,
    className
}: TimeRangeInputLabelProps) => {
    const rootClassName = 'time-range-input-label';
    const containerClassName = classnames(rootClassName, className);

    return (
        <label
            className={containerClassName}
            data-testid={rootClassName}
            htmlFor={labelFor || undefined}
        >
            {children}
        </label>
    );
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { resetOnLogout } from '~/reducers/common-actions';
import type { RootState } from '~/store';
import dateUtils from '~/utils/date-utils';

export const selectedDateSlice = createSlice({
    name: 'selectedDate',
    initialState: '',
    reducers: {
        setSelectedDate: (
            state: string,
            action: PayloadAction<string>
        ): string => {
            const date = dateUtils.convertISODateToJsDate(action.payload);
            return date ? dateUtils.convertJsDateToISODate(date) : state;
        },
        resetSelectedDate: (): string => {
            return '';
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetOnLogout, () => {
            return '';
        });
    }
});

// available selected date actions
export const { setSelectedDate, resetSelectedDate } = selectedDateSlice.actions;

// selector of selectedState state
export const selectDate = (state: RootState): string => {
    return state.selectedDate;
};

export const selectDateOnly = (state: RootState): string => {
    return dateUtils.convertToISODateOnly(state.selectedDate) || '';
};

// selectedDate reducer
export default selectedDateSlice.reducer;

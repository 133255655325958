import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useMutation } from 'react-query';

import { selectMainClient } from '~/reducers/mainClientSlice';
import CustomMappingApi from '~/api/CustomMappingApi';
import { useGetCustomMapping } from '~/hooks';
import { getPayload } from '~/hooks/useUpdateCustomMapping/utils';
import { TaskDefaultUpdateParams } from '~/components/AdvancedConfigurationPage/components/TaskFieldDefaultsSubPage/types';

export const useUpdateCustomMapping = () => {
    const { data: currentMapping } = useGetCustomMapping();
    const mainClient = useSelector(selectMainClient);
    const { id: mainClientId = '' } = mainClient || {};

    const executeUpdate = useCallback(
        (values) => {
            const formattedValues = getPayload(values);
            const currentDefaults = currentMapping?.defaults || {};
            const allDefaults = { ...currentDefaults, ...formattedValues };
            const newMapping = {
                ...(currentMapping || {}),
                defaults: allDefaults
            };
            return CustomMappingApi.update({
                clientId: mainClientId,
                customMapping: newMapping
            });
        },
        [currentMapping, mainClientId]
    );

    const { mutateAsync: updateCustomMappingDefaults } = useMutation(
        (values: TaskDefaultUpdateParams) => {
            return executeUpdate(values);
        },
        {
            onError: (error) => {
                console.error(error);
            }
        }
    );

    return {
        updateCustomMappingDefaults
    };
};

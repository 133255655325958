import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState
} from 'react';
import { useTranslation } from 'react-i18next';
import ReactDatePicker from 'react-datepicker';
import { useSelector } from 'react-redux';

import {
    selectSelectedEquipment,
    selectSelectedTaskType
} from '~/reducers/addTaskSlice';
import { selectSelectedEquipmentIds } from '~/reducers/selectedEquipmentIdsSlice';

import {
    equipmentIdValidator,
    floatValidator,
    threeDecimalPlacesNonNegativeFloatValidator
} from '~/hooks/useControlledInput';
import { useGetCompartments } from '~/hooks/useGetCompartments';
import { useIntermodalFeatures } from '~/hooks/useIntermodalFeatures';

import { AddressTaskTypes, TaskTypes } from '~/api/types';

import { ValidatedInput } from '~/components/ValidatedInput';
import {
    LabelInput,
    Slider,
    MultiCompartmentInputs,
    DatePickerHeader
} from '~/ui';
import DropDownMenu, {
    menuTitleTextDataTestIdSuffix
} from '~/ui/components/DropDownMenu/DropDownMenu';
import DropDownMenuItem from '~/ui/components/DropDownMenu/DropDownMenuItem';
import { labelInputTestIdSuffix } from '~/ui/components/LabelInput/LabelInput';
import { LabelDropdown } from '~/ui/components/LabelDropdown';

import { useAddTaskModalContext } from '../AddTaskModalContext';
import {
    className as addTaskClassName,
    clientTaskRequiredFieldMap,
    AddTaskModalTimeWindow as TimeWindow,
    VehicleType,
    vehicleTypeToTranslationKeyMap,
    taskPriorityTranslationSliderLabels,
    prioritySliderValueLookup
} from '../utils/addTaskModalUtils';
import { Invoice } from '../Invoice';
import { AddItemButton } from '../AddItemButton';
import { DeleteButton } from '../DeleteButton';

import constants from '~/utils/constants';

import './TaskInformationTabContent.scss';

export const className = `${addTaskClassName}-taskInformationTab`;

export const testId = 'taskInformationTab_testId';
export const taskPriorityWrapperTestId =
    'taskInformationTab_taskPriorityTestId';
export const addTimeWindowBtnTestId =
    'taskInformationTab_addTimeWindowBtnTestId';
export const timeWindowRowTestId = 'taskInformationTab_timeWindowRowTestId';
export const deleteTimeWindowBtnTestId =
    'taskInformationTab_deleteTimeWindowBtnTestId';
export const serviceTimeWrapperTestId =
    'taskInformationTab_serviceTimeWrapperTestId';
export const serviceTimeTestId = `${serviceTimeWrapperTestId}-${labelInputTestIdSuffix}`;
export const routeIdWrapperTestId = 'taskInformationTab_routeIdTestId';
export const routeIdTestId = `${routeIdWrapperTestId}-${labelInputTestIdSuffix}`;
export const vehicleTypeWrapperTestId = 'taskInformationTab_vehicleTypeTestId';
export const vehicleTypeMenuTextTestId = `${vehicleTypeWrapperTestId}-${menuTitleTextDataTestIdSuffix}`;
export const sizeWrapperTestId = 'taskInformationTab_sizeTestId';
export const sizeTestId = `${sizeWrapperTestId}-${labelInputTestIdSuffix}`;
export const weightWrapperTestId = 'taskInformationTab_weightTestId';
export const weightTestId = `${weightWrapperTestId}-${labelInputTestIdSuffix}`;
export const equipmentIdWrapperTestId = 'taskInformationTab_equipmentIdTestId';
export const equipmentIdTestId = `${equipmentIdWrapperTestId}-${labelInputTestIdSuffix}`;
export const equipmentTypeWrapperTestId =
    'taskInformationTab_equipmentTypeTestId';
export const equipmentTypeTestId = `${equipmentTypeWrapperTestId}-${labelInputTestIdSuffix}`;

export const lowPriorityMenuItemTestId =
    'taskInformationTab_lowPriorityMenuItemTestId';
export const mediumPriorityMenuItemTestId =
    'taskInformationTab_mediumPriorityMenuItemTestId';
export const highPriorityMenuItemTestId =
    'taskInformationTab_highPriorityMenuItemTestId';

export const sideLoaderMenuItemTestId =
    'taskInformationTab_sideLoaderMenuItemTestId';
export const endLoaderMenuItemTestId =
    'taskInformationTab_endLoaderMenuItemTestId';

export function TaskInformationTabContent(): JSX.Element {
    const vehicleTypeDropDownMenuRef = useRef<HTMLDivElement>(null);
    const { t } = useTranslation(['addTask']);
    const [isVehicleTypeDropDownMenuOpen, setIsVehicleTypeDropDownMenuOpen] =
        useState(false);
    const selectedEquipment = useSelector(selectSelectedEquipment);
    const selectedEquipmentIds = useSelector(selectSelectedEquipmentIds);
    const selectedTaskType = useSelector(selectSelectedTaskType);

    const {
        clientTask,
        onStringFieldChange,
        onOtherFieldChange,
        onTimeWindowsChange,
        onInputChange
    } = useAddTaskModalContext();
    const { isMultiCompartment } = useGetCompartments();
    const {
        carKind: presetEquipmentTypeValues,
        enableEditCarKind,
        enableEditEquipmentId
    } = useIntermodalFeatures();

    const hasPresetEquipmentTypes =
        Array.isArray(presetEquipmentTypeValues) &&
        presetEquipmentTypeValues.length > 0;

    const toggleVehicleTypeDropDown = useCallback(() => {
        setIsVehicleTypeDropDownMenuOpen((isDropDownOpen) => !isDropDownOpen);
    }, []);
    const closeVehicleTypeDropDown = useCallback(() => {
        setIsVehicleTypeDropDownMenuOpen(false);
    }, []);

    const isTwoPartTask = clientTask.taskType === TaskTypes.TWOPART;
    const currentTaskType = isTwoPartTask
        ? AddressTaskTypes.PICKUP
        : (selectedTaskType as unknown as AddressTaskTypes);
    const timeSlotsLabel = isTwoPartTask
        ? t(`fields.taskType.fields.${currentTaskType}.label`)
        : '';

    const vehicleTypeDropDownTitle =
        clientTask.vehicleType !== null
            ? t(vehicleTypeToTranslationKeyMap[clientTask.vehicleType])
            : '';
    const vehicleTypeDropDownMenuItemsData = useMemo(
        () => [
            {
                translationKey: 'fields.vehicleType.fields.sideLoader.label',
                onClick: () => {
                    onStringFieldChange('vehicleType', VehicleType.SIDE_LOADER);
                },
                menuItemTestId: sideLoaderMenuItemTestId
            },
            {
                translationKey: 'fields.vehicleType.fields.endLoader.label',
                onClick: () => {
                    onStringFieldChange('vehicleType', VehicleType.END_LOADER);
                },
                menuItemTestId: endLoaderMenuItemTestId
            }
        ],
        [onStringFieldChange]
    );

    const onAddNewTimeWindowClick = useCallback(
        (taskType: AddressTaskTypes) => {
            const newTimeWindows = [
                ...(clientTask[`${taskType}TimeWindows`] ?? [])
            ];
            newTimeWindows.push({});
            onTimeWindowsChange(`${taskType}TimeWindows`, newTimeWindows);
        },
        [onTimeWindowsChange, clientTask]
    );

    const onServiceTimeChange = useCallback(
        (newServiceTimeMins: string, taskType: AddressTaskTypes) => {
            onStringFieldChange(
                `${taskType}ServiceTimeMins`,
                newServiceTimeMins
            );
        },
        [onStringFieldChange]
    );

    const handleServiceTimeChange = (value: string) => {
        onServiceTimeChange(value, currentTaskType);
    };

    const handleServiceTimeChangeDelivery = (value: string) => {
        onServiceTimeChange(value, AddressTaskTypes.DELIVERY);
    };

    const onRouteIdChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            onInputChange('routeId', e);
        },
        [onInputChange]
    );

    const onSizeChange = useCallback(
        (newSize: string) => {
            onStringFieldChange('size', newSize);
        },
        [onStringFieldChange]
    );

    const onEquipmentIdChange = useCallback(
        (newEquipmentId: string) => {
            onStringFieldChange('equipmentId', newEquipmentId);
        },
        [onStringFieldChange]
    );

    const onEquipmentTypeChange = useCallback(
        (newEquipmentType: string) => {
            onStringFieldChange('carKind', newEquipmentType);
        },
        [onStringFieldChange]
    );

    const onWeightChange = useCallback(
        (newWeight: string) => {
            onStringFieldChange('weight', newWeight);
        },
        [onStringFieldChange]
    );

    const onPriorityChange = useCallback(
        (e) => {
            const value = prioritySliderValueLookup[e.target.value];
            onOtherFieldChange('priority', value);
        },
        [onOtherFieldChange]
    );

    useEffect(() => {
        if (!selectedEquipment) return;
        onEquipmentIdChange(selectedEquipment.id);
    }, [selectedEquipment, onEquipmentIdChange]);

    useEffect(() => {
        if (selectedEquipmentIds.length !== 1) return;
        const [equipmentId] = selectedEquipmentIds;
        onEquipmentIdChange(equipmentId);
    }, [selectedEquipmentIds, onEquipmentIdChange]);

    return (
        <div className={`_d-flex _fd-column ${className}`} data-testid={testId}>
            {/* First row flex layout */}
            <div className="_d-flex task-information-tab__row">
                {/* Task priority layout */}
                <div className="_fd-column _w-100 task-information-tab__task-priority">
                    <div className="_d-flex task-information-tab__task-priority-label">
                        <span className={`${addTaskClassName}_label`}>
                            {t('fields.priority.label')}
                        </span>
                        {clientTaskRequiredFieldMap(selectedTaskType)
                            .priority && (
                            <span className="label-input_asterisk">*</span>
                        )}
                    </div>
                    <Slider
                        className="task-information-tab__slider"
                        labels={taskPriorityTranslationSliderLabels}
                        step={constants.taskPriority.SLIDER_STEP}
                        defaultValue={
                            prioritySliderValueLookup[
                                clientTask.priority
                            ]?.toString() ?? constants.taskPriority.SLIDER_MIN
                        }
                        min={constants.taskPriority.SLIDER_MIN}
                        max={constants.taskPriority.SLIDER_MAX}
                        onChange={onPriorityChange}
                        data-testid={taskPriorityWrapperTestId}
                    />
                </div>
                <Invoice />
            </div>
            <div className={`${addTaskClassName}_divider`} />
            {/* Second row flex layout */}
            <div className="_d-flex task-information-tab__row">
                {/* Time windows layout */}
                <div className="_fd-column _w-100 task-information-tab__time-windows">
                    {/* Time windows header layout */}
                    <div className="_d-flex _jc-space-between _ai-center">
                        <div className="time-windows-label_layout">
                            <span className={`${addTaskClassName}_label`}>
                                {t('fields.timeWindows.label', {
                                    taskType: timeSlotsLabel
                                })}
                            </span>
                            {clientTaskRequiredFieldMap(selectedTaskType)[
                                `${currentTaskType}TimeWindows`
                            ].minLength > 0 && (
                                <span className="label-input_asterisk">*</span>
                            )}
                        </div>
                        <AddItemButton
                            data-testid={addTimeWindowBtnTestId}
                            text={t(
                                'fields.timeWindows.addNewTimeWindowButtonLabel'
                            )}
                            onClick={() =>
                                onAddNewTimeWindowClick(currentTaskType)
                            }
                        />
                    </div>
                    {clientTask[`${currentTaskType}TimeWindows`].map((_, i) => {
                        return (
                            <TimeWindowRow
                                /* eslint-disable-next-line react/no-array-index-key */
                                key={i}
                                timeWindowIndex={i}
                                taskType={currentTaskType}
                            />
                        );
                    })}
                </div>
                <ValidatedInput
                    inputComponent={LabelInput}
                    validator={floatValidator}
                    label={t('fields.serviceTimeMins.label')}
                    value={clientTask[`${currentTaskType}ServiceTimeMins`]}
                    onValueChange={handleServiceTimeChange}
                    required={
                        clientTaskRequiredFieldMap(selectedTaskType)[
                            `${currentTaskType}ServiceTimeMins`
                        ]
                    }
                    data-testid={serviceTimeWrapperTestId}
                />
            </div>

            {isTwoPartTask && (
                <>
                    <div className={`${addTaskClassName}_divider`} />
                    <div className="_d-flex task-information-tab__row">
                        {/* Time windows layout */}
                        <div className="_fd-column _w-100 task-information-tab__time-windows">
                            {/* Time windows header layout */}
                            <div className="_d-flex _jc-space-between _ai-center">
                                <div className="time-windows-label_layout">
                                    <span
                                        className={`${addTaskClassName}_label`}
                                    >
                                        {t('fields.timeWindows.label', {
                                            taskType: 'Delivery'
                                        })}
                                    </span>
                                    {clientTaskRequiredFieldMap(
                                        selectedTaskType
                                    ).deliveryTimeWindows.minLength > 0 && (
                                        <span className="label-input_asterisk">
                                            *
                                        </span>
                                    )}
                                </div>
                                <AddItemButton
                                    data-testid={addTimeWindowBtnTestId}
                                    text={t(
                                        'fields.timeWindows.addNewTimeWindowButtonLabel'
                                    )}
                                    onClick={() =>
                                        onAddNewTimeWindowClick(
                                            AddressTaskTypes.DELIVERY
                                        )
                                    }
                                />
                            </div>
                            {clientTask.deliveryTimeWindows.map((_, i) => {
                                return (
                                    <TimeWindowRow
                                        /* eslint-disable-next-line react/no-array-index-key */
                                        key={i}
                                        timeWindowIndex={i}
                                        taskType={AddressTaskTypes.DELIVERY}
                                    />
                                );
                            })}
                        </div>
                        <ValidatedInput
                            inputComponent={LabelInput}
                            validator={floatValidator}
                            label={t('fields.serviceTimeMins.label')}
                            value={clientTask.deliveryServiceTimeMins}
                            onValueChange={handleServiceTimeChangeDelivery}
                            required={
                                clientTaskRequiredFieldMap(selectedTaskType)
                                    .deliveryServiceTimeMins
                            }
                            data-testid={serviceTimeWrapperTestId}
                        />
                    </div>
                </>
            )}

            <div className={`${addTaskClassName}_divider`} />
            {/* Third row flex layout */}
            {isMultiCompartment && (
                <div className="_mb-6">
                    <MultiCompartmentInputs
                        title={t('fields.multiCompartment.label')}
                        isRequiredField={false}
                    />
                </div>
            )}
            <div className="_d-flex task-information-tab__row">
                <ValidatedInput
                    inputComponent={LabelInput}
                    validator={threeDecimalPlacesNonNegativeFloatValidator}
                    label={t('fields.weight.label')}
                    value={clientTask.weight}
                    onValueChange={onWeightChange}
                    required={
                        clientTaskRequiredFieldMap(selectedTaskType).weight
                    }
                    data-testid={weightWrapperTestId}
                    placeholder={t('fields.weight.placeholder')}
                />
                {!isMultiCompartment && (
                    <ValidatedInput
                        inputComponent={LabelInput}
                        validator={threeDecimalPlacesNonNegativeFloatValidator}
                        label={t('fields.size.label')}
                        value={clientTask.size}
                        onValueChange={onSizeChange}
                        required={
                            clientTaskRequiredFieldMap(selectedTaskType).size
                        }
                        data-testid={sizeWrapperTestId}
                        placeholder={t('fields.size.placeholder')}
                    />
                )}
            </div>
            <div className={`${addTaskClassName}_divider`} />
            {/* Fourth row flex layout */}
            <div className="_d-flex task-information-tab__row">
                <LabelInput
                    label={t('fields.routeId.label')}
                    value={clientTask.routeId}
                    onChange={onRouteIdChange}
                    required={
                        clientTaskRequiredFieldMap(selectedTaskType).routeId
                    }
                    data-testid={routeIdWrapperTestId}
                />
                <div className="_fd-column _w-100 task-information-tab__vehicle-type">
                    <div className="_d-flex task-information-tab__vehicle-type-label">
                        <span className={`${addTaskClassName}_label`}>
                            {t('fields.vehicleType.label')}
                        </span>
                        {clientTaskRequiredFieldMap(selectedTaskType)
                            .vehicleType && (
                            <span className="label-input_asterisk">*</span>
                        )}
                    </div>
                    <DropDownMenu
                        title={{
                            name: vehicleTypeDropDownTitle
                        }}
                        placeholder={t('fields.vehicleType.placeholder')}
                        styles={{
                            innerContainer:
                                'dropdown-flex-space-between dropdown_outlined',
                            menuTitle: 'dropdown-selection',
                            menuTitleText: 'dropdown-title-text',
                            dropdownToggleColor: 'var(--color-galaxy-800)'
                        }}
                        ref={vehicleTypeDropDownMenuRef}
                        isOpen={isVehicleTypeDropDownMenuOpen}
                        toggle={toggleVehicleTypeDropDown}
                        close={closeVehicleTypeDropDown}
                        icon="chevronDown"
                        position="down"
                        data-testid={vehicleTypeWrapperTestId}
                    >
                        {vehicleTypeDropDownMenuItemsData.map(
                            ({ translationKey, onClick, menuItemTestId }) => (
                                <DropDownMenuItem
                                    key={translationKey}
                                    menuItem={{
                                        text: t(translationKey),
                                        onClick
                                    }}
                                    data-testid={menuItemTestId}
                                />
                            )
                        )}
                    </DropDownMenu>
                </div>
            </div>
            <div className={`${addTaskClassName}_divider`} />
            <div className="_d-flex task-information-tab__row task-information-tab__equipment-id-input">
                {enableEditEquipmentId && (
                    <ValidatedInput
                        inputComponent={LabelInput}
                        validator={equipmentIdValidator}
                        label={t('fields.equipmentId.label')}
                        value={
                            clientTask.equipmentId ||
                            selectedEquipment?.id ||
                            ''
                        }
                        onValueChange={onEquipmentIdChange}
                        required={
                            clientTaskRequiredFieldMap(selectedTaskType)
                                .equipmentId
                        }
                        data-testid={equipmentIdWrapperTestId}
                        placeholder={t('fields.equipmentId.placeholder')}
                    />
                )}
                {enableEditCarKind && !hasPresetEquipmentTypes && (
                    <ValidatedInput
                        inputComponent={LabelInput}
                        label={t('fields.equipmentType.label')}
                        value={
                            clientTask.carKind || selectedEquipment?.type || ''
                        }
                        onValueChange={onEquipmentTypeChange}
                        required={
                            clientTaskRequiredFieldMap(selectedTaskType).carKind
                        }
                        data-testid={equipmentTypeWrapperTestId}
                        placeholder={t('fields.equipmentType.placeholder')}
                    />
                )}
                {enableEditCarKind && hasPresetEquipmentTypes && (
                    <LabelDropdown
                        label={t('fields.equipmentType.label')}
                        value={
                            clientTask.carKind || selectedEquipment?.type || ''
                        }
                        validValues={presetEquipmentTypeValues}
                        onChange={onEquipmentTypeChange}
                        required={
                            clientTaskRequiredFieldMap(selectedTaskType).carKind
                        }
                        data-testid={equipmentTypeWrapperTestId}
                        placeholder={t('fields.equipmentType.placeholder')}
                    />
                )}
            </div>
        </div>
    );
}

export const startTimeInputTestId = 'taskInformationTab_startTimeInputTestId';
export const endTimeInputTestId = 'taskInformationTab_endTimeInputTestId';

interface TimeWindowRowProps {
    timeWindowIndex: number;
    taskType: AddressTaskTypes;
}

function TimeWindowRow({ timeWindowIndex, taskType }: TimeWindowRowProps) {
    const { clientTask, onTimeWindowsChange } = useAddTaskModalContext();
    const { t } = useTranslation(['addTask']);
    const currentTimeWindows = clientTask[`${taskType}TimeWindows`];

    const onTimeWindowChange = useCallback(
        (timeWindowField: keyof TimeWindow, index: number, value: Date) => {
            const existingWindow = currentTimeWindows?.[index] ?? {};
            const newWindow: TimeWindow = {
                ...existingWindow,
                [timeWindowField]: value
            };
            const newTimeWindows = [...(currentTimeWindows ?? [])];
            newTimeWindows[index] = newWindow;
            onTimeWindowsChange(`${taskType}TimeWindows`, newTimeWindows);
        },
        [onTimeWindowsChange, currentTimeWindows, taskType]
    );

    const onDeleteClick = useCallback(() => {
        /**
         * @TODO For time's sake, we temporarily use `confirm` as the modal confirmation
         * Come back to this and replace with a custom modal UI
         */
        /* eslint-disable-next-line no-restricted-globals, no-alert */
        const shouldDelete = confirm(
            t('taskInformationTab.confirmDeleteTimeWindowMessage')
        );

        if (shouldDelete) {
            const timeWindowsClone = [...currentTimeWindows];
            timeWindowsClone.splice(timeWindowIndex, 1);
            onTimeWindowsChange(`${taskType}TimeWindows`, timeWindowsClone);
        }
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [onTimeWindowsChange, currentTimeWindows, taskType]);

    const timeWindow = useMemo(
        () => currentTimeWindows?.[timeWindowIndex],
        [currentTimeWindows, timeWindowIndex]
    );

    return (
        <div
            className="time-window__container"
            data-testid={timeWindowRowTestId}
        >
            <ReactDatePicker
                selected={timeWindow?.start}
                onChange={(value) =>
                    onTimeWindowChange('start', timeWindowIndex, value as Date)
                }
                renderCustomHeader={DatePickerHeader}
                showTimeInput
                timeInputLabel="Time Window*"
                dateFormat="h:mm aa MM/dd/yyyy"
                shouldCloseOnSelect={false}
                data-testid={startTimeInputTestId}
                className={startTimeInputTestId}
            />

            <ReactDatePicker
                selected={timeWindow?.end}
                onChange={(value) =>
                    onTimeWindowChange('end', timeWindowIndex, value as Date)
                }
                renderCustomHeader={DatePickerHeader}
                showTimeInput
                timeInputLabel="Time Window*"
                dateFormat="h:mm aa MM/dd/yyyy"
                shouldCloseOnSelect={false}
                data-testid={endTimeInputTestId}
                className={endTimeInputTestId}
            />
            {timeWindowIndex !== 0 ? (
                <DeleteButton
                    className="time-window__button--delete"
                    data-testid={deleteTimeWindowBtnTestId}
                    onClick={onDeleteClick}
                />
            ) : null}
        </div>
    );
}

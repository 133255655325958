import { createSlice, createSelector } from '@reduxjs/toolkit';
import { resetOnLogout } from '~/reducers/common-actions';
import type { RootState } from '~/store';
import { ApiClient } from '~/api/types';

/**
 * The selected client state
 */
type SelectedClientState = Record<ApiClient['id'], boolean>;

/**
 * Selected client reducer actions
 */
export const selectedClientsSlice = createSlice({
    name: 'selectedClients',
    initialState: {} as SelectedClientState,
    reducers: {
        setSelectedClients: (state, action) => {
            const clientIds = action.payload;
            const newState = clientIds.reduce(
                (aggregator: Record<string, boolean>, id: string) => {
                    aggregator[id] = true;
                    return aggregator;
                },
                {}
            );

            return newState;
        },
        resetSelectedClients: (): SelectedClientState => {
            return {};
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetOnLogout, () => {
            return {};
        });
    }
});

export const { setSelectedClients, resetSelectedClients } =
    selectedClientsSlice.actions;

export const selectSelectedClients = (state: RootState): SelectedClientState =>
    state.selectedClients;

export const selectSelectedClientIds = createSelector(
    (state: RootState) => state.selectedClients,
    (selectedClients) => {
        return Object.keys(selectedClients);
    }
);

export default selectedClientsSlice.reducer;

import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectClients } from '~/reducers/clientsSlice';
import { selectMainClient } from '~/reducers/mainClientSlice';
import { selectSelectedClients } from '~/reducers/selectedClientsSlice';
import { useFeatureFlag } from '~/hooks';
import { FeatureFlag } from '~/utils/feature-flags-utils';
import { UseClientTimezone } from './types';

export const useClientTimezone = () => {
    const clients = useSelector(selectClients);
    const mainClient = useSelector(selectMainClient);
    const selectedClients = useSelector(selectSelectedClients);
    const isClientTimezoneFlagEnabled = useFeatureFlag(
        FeatureFlag.CLIENT_TIMEZONE
    );

    return useMemo<UseClientTimezone>(() => {
        const clientIds = Object.keys(selectedClients || {});
        const isSingleClientSelected = clientIds.length === 1;
        const selectedClient = isSingleClientSelected
            ? clients[clientIds[0]]
            : mainClient;

        const clientTimezone = selectedClient?.timezone || 'system';
        const enabledTimezone = isClientTimezoneFlagEnabled
            ? clientTimezone
            : 'system';

        return {
            client: selectedClient,
            timezone: clientTimezone,
            enabledTimezone
        };
    }, [selectedClients, mainClient, clients, isClientTimezoneFlagEnabled]);
};

import { useMemo } from 'react';

import { useMapUtils } from '~/hooks';

import { LiveStop, PlanStop } from '~/data-classes';
import { SocketScheduleItem } from '~/api/types';
import { StackMarkerTasks } from '~/ui/components/StackPinMarker/types';

import { unplannedCSS } from '~/utils/styleContants';
import { colorUtils } from '~/utils/color-utils';
import { idUtils } from '~/utils/id-utils';

import { useStackMarkerHasCompleted } from '../useStackMarkerHasCompleted';

export const useStackMarkerStyle = (tasks: StackMarkerTasks) => {
    const COMPLETED_MARKER_BACKGROUND = 'white';
    const SECONDARY_GRADIENT_BACKGROUND = 'black';

    const { isPlanRouteMode, isLiveRouteMode } = useMapUtils();

    const stackMarkerHasCompleted = useStackMarkerHasCompleted(tasks);

    const containsMultipleRoutes = useMemo(() => {
        if (isPlanRouteMode) {
            const sets = new Set(
                tasks.map((task) => {
                    const { clientRouteId } = task as PlanStop;
                    return clientRouteId;
                })
            );
            return sets.size >= 2;
        }
        const sets = new Set(
            tasks.map((task) => {
                const { euid, driver } = task as unknown as SocketScheduleItem;
                return euid || driver;
            })
        );
        return sets.size >= 2;
    }, [tasks, isPlanRouteMode]);

    const markerStyle = useMemo(() => {
        if (containsMultipleRoutes) {
            return {
                backgroundColor: stackMarkerHasCompleted
                    ? COMPLETED_MARKER_BACKGROUND
                    : SECONDARY_GRADIENT_BACKGROUND,
                border: stackMarkerHasCompleted ? '0.1rem solid black' : 'none'
            };
        }

        if (isLiveRouteMode) {
            const task = tasks[0] as unknown as LiveStop;
            const { clientId, driver } = task as unknown as LiveStop;
            const liveMarkerCSS = colorUtils.getWebColorsForId(
                idUtils.getCombinedId(clientId as string, driver as string)
            );
            return stackMarkerHasCompleted
                ? {
                      backgroundColor: COMPLETED_MARKER_BACKGROUND,
                      border: `0.1rem solid ${liveMarkerCSS.backgroundColor}`
                  }
                : liveMarkerCSS;
        }

        const task = tasks[0] as PlanStop;
        if (task.isPlanned) {
            return task.colorCSS;
        }
        return unplannedCSS;
    }, [
        containsMultipleRoutes,
        tasks,
        isLiveRouteMode,
        stackMarkerHasCompleted
    ]);

    return {
        markerStyle,
        shouldShowGradient: containsMultipleRoutes
    };
};

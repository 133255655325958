import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { addToast } from '~/reducers/toastsSlice';
import { ApiTask, DeliveryTaskProps, PickupTaskProps } from '~/api/types';
import TasksApi from '~/api/TasksApi';
import { resetSelectedMapStops } from '~/reducers/selectedMapStopsSlice';
import { addNewTask } from '~/reducers/tasksSlice';
import { selectDate } from '~/reducers/selectedDateSlice';
import { updateTaskMetrics } from '~/reducers/taskMetricsSlice';

const getUnselectedInvoicesIds = ({
    apiTask,
    selectedInvoicesIds
}: {
    apiTask: ApiTask;
    selectedInvoicesIds: string[];
}) => {
    const { props: taskProps } = apiTask;
    const { pickupInvoices = [] } = taskProps as PickupTaskProps;
    const { deliveryInvoices = [] } = taskProps as DeliveryTaskProps;
    const allInvoiceIds = [...pickupInvoices, ...deliveryInvoices].map(
        ({ invoiceId }) => invoiceId
    );
    const setOfSelectedInvoicesIds = new Set(selectedInvoicesIds);

    return allInvoiceIds.filter(
        (invoiceId) => !setOfSelectedInvoicesIds.has(invoiceId)
    );
};

export const useSplitTaskByInvoice = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation('duplicateTaskModal');
    const selectedDate = useSelector(selectDate);

    const splitTaskByInvoice = useCallback(
        async ({
            apiTask,
            onClose,
            selectedInvoicesIds
        }: {
            apiTask: ApiTask;
            onClose: () => void;
            selectedInvoicesIds: string[];
        }) => {
            /**
             * @todo move to be validated by formik
             * + it should be an obvious validation error for the user to correct
             * + or disable the submit button until the data is valid
             */
            if (!selectedInvoicesIds.length) {
                console.warn('There must be selected invoices');
                return;
            }

            const unselectedInvoicesIds = getUnselectedInvoicesIds({
                apiTask,
                selectedInvoicesIds
            });

            /**
             * @todo move to be validated by formik
             * + it should be an obvious validation error for the user to correct
             * + or disable the submit button until the data is valid
             */
            if (!unselectedInvoicesIds.length) {
                console.warn('There must be unselected invoices');
                return;
            }

            const { id: taskId, props: taskProps } = apiTask;
            const { size, weight } = taskProps;

            const apiRequestPayload = [
                selectedInvoicesIds,
                unselectedInvoicesIds
            ].map((invoiceIds) => ({ invoiceIds, size, weight }));

            try {
                const {
                    data: { data: newApiTasks }
                } = await TasksApi.splitTaskByInvoice({
                    taskId,
                    payload: apiRequestPayload
                });
                onClose();
                dispatch(
                    addToast({
                        message: t('unassignedTaskCreated'),
                        variant: 'success'
                    })
                );
                dispatch(resetSelectedMapStops());
                newApiTasks.forEach((newApiTask) => {
                    dispatch(addNewTask(newApiTask));
                });
                dispatch(updateTaskMetrics(selectedDate));
            } catch (error) {
                console.error(error);
            }
        },
        [dispatch, selectedDate, t]
    );

    return {
        splitTaskByInvoice
    };
};

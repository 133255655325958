import { UseQueryOptions } from 'react-query';
import { ApiAddress } from '~/api/types';
import constants from '~/utils/constants';

export const REACT_QUERY_KEY = constants.reactQueryKeys.ADDRESS_BY_ID;

export type UseFetchMainClientAddressReactQueryOptions = Omit<
    UseQueryOptions<ApiAddress, Error>,
    'queryKey' | 'queryFn'
>;

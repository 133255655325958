import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { useFeatureFlag } from '~/hooks';
import { FeatureFlag } from '~/utils/feature-flags-utils';

import { RadioInputData } from '~/ui/components/RadioGroup';
import { RadioGroup } from '~/ui';
import {
    ActionTypes,
    DuplicateTaskModalFormProps,
    actionToItemMapping
} from '../types';
import { DuplicateTaskModalCollapsible } from '../DuplicateTaskModalCollapsible';

import './DuplicateTaskModalActions.scss';

export const DuplicateTaskModalActions = () => {
    const rootClassName = 'duplicate-task-modal-actions';
    const { t } = useTranslation('duplicateTaskModal');

    const isEnabledSplitTaskByInventory = useFeatureFlag(
        FeatureFlag.SPLIT_TASK_BY_INVENTORY
    );

    const { values, setFieldValue } =
        useFormikContext<DuplicateTaskModalFormProps>();

    const setSelectedActionType = useCallback(
        (actionType: ActionTypes) => {
            setFieldValue('actionType', actionType);
            setFieldValue('itemType', actionToItemMapping[actionType]);
        },
        [setFieldValue]
    );

    const radioData = useMemo(() => {
        const rootTestId = 'duplicate-task-modal-actions__radio-input';
        const data: RadioInputData<ActionTypes>[] = [
            {
                id: `${rootClassName}-${ActionTypes.MOVE_INVOICE}`,
                label: t('settings.moveInvoice.label'),
                description: t('settings.moveInvoice.description'),
                value: ActionTypes.MOVE_INVOICE,
                'data-testid': `${rootTestId}--${ActionTypes.MOVE_INVOICE}`
            }
        ];

        if (isEnabledSplitTaskByInventory) {
            data.push({
                id: `${rootClassName}-${ActionTypes.MOVE_INVENTORY}`,
                label: t('settings.moveInventory.label'),
                description: t('settings.moveInventory.description'),
                value: ActionTypes.MOVE_INVENTORY,
                'data-testid': `${rootTestId}--${ActionTypes.MOVE_INVENTORY}`
            });
        }

        return data;
    }, [isEnabledSplitTaskByInventory, t]);

    return (
        <DuplicateTaskModalCollapsible
            title={t('settings.title')}
            subtitle={t(`settings.${values.actionType}.label`)}
        >
            <RadioGroup
                radioData={radioData}
                onChange={setSelectedActionType}
                value={values.actionType}
                name="actionType"
                className={rootClassName}
                data-testid={rootClassName}
                radioLabelClassName={`${rootClassName}__radio-label`}
                radioLabel-testid={`${rootClassName}__radio-label`}
                radioLabelDescriptionClassName={`${rootClassName}__radio-label-description`}
                radioInputGroupClassName={`${rootClassName}__radio-input-group`}
                radioInputGroup-testid={`${rootClassName}__radio-input-group`}
                radioInputClassName={`${rootClassName}__radio-input`}
                radio-testid={`${rootClassName}__radio-input`}
                radioGroupLabelClassName={`${rootClassName}__radio-group-label`}
                radioGroupLabel-testid={`${rootClassName}__radio-group-label`}
                radioInputLabelClassName={`${rootClassName}__radio-input-label`}
                radioInputLabel-testid={`${rootClassName}__radio-input-label`}
            />
        </DuplicateTaskModalCollapsible>
    );
};

export enum ActionTypes {
    MOVE_INVOICE = 'moveInvoice',
    MOVE_INVENTORY = 'moveInventory'
}

export enum AssignmentTypes {
    CREATE_TASK = 'createTask',
    ASSIGN_TASK_TO_DRIVER = 'assignTaskToDriver'
}

export enum ItemTypes {
    INVOICE = 'invoice',
    INVENTORY = 'inventory'
}

export const actionToItemMapping: Record<string, ItemTypes | undefined> = {
    [ActionTypes.MOVE_INVOICE]: ItemTypes.INVOICE,
    [ActionTypes.MOVE_INVENTORY]: ItemTypes.INVENTORY
};

export interface DuplicateTaskModalFormProps {
    actionType: ActionTypes;
    assignmentType: AssignmentTypes;
    itemType: ItemTypes;
    invoicesIds: string[];
}

import React from 'react';
import { FlexLayout } from '~/ui';
import { Box } from '..';
import { BaseActionBarProps, BaseActionBarButtonGroupProps } from './types';
import './BaseActionBar.scss';

export const BaseActionBar = ({ children }: BaseActionBarProps) => {
    return (
        <FlexLayout
            className="baseactionbar _p-relative"
            justifyContent="space-between"
        >
            {children}
        </FlexLayout>
    );
};

export const BaseActionBarButtonGroup = ({
    children,
    sx = {}
}: BaseActionBarButtonGroupProps) => {
    return (
        <Box className="baseactionbar_buttongroup _d-flex _ai-center" sx={sx}>
            {children}
        </Box>
    );
};

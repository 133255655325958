import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
    selectIsOpenAddTaskModal,
    selectSelectedTaskType
} from '~/reducers/addTaskSlice';
import {
    ClientTask,
    ClientTaskErrorState,
    AddTaskModalTab,
    emptyClientTask,
    errorStateHasError,
    generateErrorStates,
    dataIsMissingRequiredFields,
    getAddressTabRequiredFieldMap,
    clientTaskRequiredFieldMap,
    taskInformationTabRequiredFieldMap,
    inventoryItemsTabRequiredFieldMap,
    notesTabRequiredFieldMap,
    isInvoicesFieldWithError
} from '../utils/addTaskModalUtils';
import { isEquipmentIdHasValidLength, useClientTimezone } from '~/hooks';
import { useClientTaskCustomMapping } from '../useClientTaskCustomMapping';
import { getSelectedTaskTimeWindows } from './utils';
import { TaskTypes } from '~/api/types';

export const useClientTaskState = () => {
    const [clientTask, setClientTask] =
        useState<Required<ClientTask>>(emptyClientTask);
    const [errorState, setErrorState] = useState<ClientTaskErrorState>({});
    const selectedTaskType = useSelector(selectSelectedTaskType);
    const isOpenAddTaskModal = useSelector(selectIsOpenAddTaskModal);
    const customClientTaskDefaults = useClientTaskCustomMapping();
    const { enabledTimezone } = useClientTimezone();

    const initialClientTask = useMemo(() => {
        return {
            ...emptyClientTask,
            ...customClientTaskDefaults
        };
    }, [customClientTaskDefaults]);

    const selectedTaskTimeWindows = useMemo(() => {
        return getSelectedTaskTimeWindows({
            isTwoPartTask: selectedTaskType === TaskTypes.TWOPART,
            customMapping: customClientTaskDefaults,
            zone: enabledTimezone
        });
    }, [selectedTaskType, customClientTaskDefaults, enabledTimezone]);

    useEffect(() => {
        if (!isOpenAddTaskModal) {
            setClientTask(initialClientTask);
        }
    }, [isOpenAddTaskModal, initialClientTask]);

    useEffect(() => {
        if (!isOpenAddTaskModal) return;

        setClientTask((prev) => ({
            ...prev,
            ...selectedTaskTimeWindows,
            externalTaskType: ''
        }));
    }, [selectedTaskType, isOpenAddTaskModal, selectedTaskTimeWindows]);

    const {
        addressTabErrorState,
        taskInformationTabErrorState,
        inventoryItemsTabErrorState,
        notesTabErrorState
    } = useMemo(() => {
        const errorStates = generateErrorStates(errorState);
        return errorStates;
    }, [errorState]);

    const hasErrorAddressTab = useMemo(() => {
        const hasError = errorStateHasError(addressTabErrorState);
        return hasError;
    }, [addressTabErrorState]);

    const hasErrorTaskInformationTab = useMemo(() => {
        const hasError = errorStateHasError(taskInformationTabErrorState);
        return hasError;
    }, [taskInformationTabErrorState]);

    const hasErrorInventoryItemsTab = useMemo(() => {
        const hasError = errorStateHasError(inventoryItemsTabErrorState);
        return hasError;
    }, [inventoryItemsTabErrorState]);

    const hasErrorNotesTab = useMemo(() => {
        const hasError = errorStateHasError(notesTabErrorState);
        return hasError;
    }, [notesTabErrorState]);

    const hasErrorModal = useMemo(() => {
        const hasError = errorStateHasError(errorState);
        return hasError;
    }, [errorState]);

    const hasMissingRequiredFieldsAddressTab = useMemo(() => {
        const addressTabRequiredFieldMap =
            getAddressTabRequiredFieldMap(selectedTaskType);
        const hasMissingRequiredFields = dataIsMissingRequiredFields(
            clientTask,
            addressTabRequiredFieldMap
        );
        return hasMissingRequiredFields;
    }, [clientTask, selectedTaskType]);

    const hasMissingRequiredFieldsTaskInformationTab = useMemo(() => {
        const hasMissingRequiredFields = dataIsMissingRequiredFields(
            clientTask,
            taskInformationTabRequiredFieldMap
        );
        return hasMissingRequiredFields;
    }, [clientTask]);

    const hasMissingRequiredFieldsInventoryItemsTab = useMemo(() => {
        const hasMissingRequiredFields = dataIsMissingRequiredFields(
            clientTask,
            inventoryItemsTabRequiredFieldMap
        );
        return hasMissingRequiredFields;
    }, [clientTask]);

    const hasMissingRequiredFieldsNotesTab = useMemo(() => {
        const hasMissingRequiredFields = dataIsMissingRequiredFields(
            clientTask,
            notesTabRequiredFieldMap
        );
        return hasMissingRequiredFields;
    }, [clientTask]);

    const hasMissingRequiredFieldsModal = useMemo(() => {
        const modalRequiredFieldMap =
            clientTaskRequiredFieldMap(selectedTaskType);
        const hasMissingRequiredFields = dataIsMissingRequiredFields(
            clientTask,
            modalRequiredFieldMap
        );
        return hasMissingRequiredFields;
    }, [clientTask, selectedTaskType]);

    const isInvoicesWithError = isInvoicesFieldWithError(clientTask);

    const isValidEquipmentId = !isEquipmentIdHasValidLength(
        clientTask.equipmentId
    );

    const hasIssuesAddressTab =
        hasErrorAddressTab || hasMissingRequiredFieldsAddressTab;

    const hasIssuesTaskInformationTab =
        hasErrorTaskInformationTab ||
        hasMissingRequiredFieldsTaskInformationTab ||
        isInvoicesWithError ||
        isValidEquipmentId;

    const hasIssuesInventoryItemsTab =
        hasErrorInventoryItemsTab || hasMissingRequiredFieldsInventoryItemsTab;

    const hasIssuesNotesTab =
        hasErrorNotesTab || hasMissingRequiredFieldsNotesTab;

    /**
     * Errors vs issues:
     * Errors should display to the user with some alarming UI, like a highlighted red field
     * Issues should prevent the user from completing the end task of the modal, in this case
     * disabling the `Save and Click` button
     *
     * The modal can have issues but no errors, since we don't want to alert the user of unfilled
     * fields that they haven't touched yet
     */
    const hasIssuesModal =
        hasErrorModal ||
        hasMissingRequiredFieldsModal ||
        isInvoicesWithError ||
        isValidEquipmentId;

    const tabIssuesMap: { [key in AddTaskModalTab]: boolean } = {
        [AddTaskModalTab.ADDRESS]: hasIssuesAddressTab,
        [AddTaskModalTab.TASK_INFORMATION]: hasIssuesTaskInformationTab,
        [AddTaskModalTab.INVENTORY_ITEMS]: hasIssuesInventoryItemsTab,
        [AddTaskModalTab.NOTES]: hasIssuesNotesTab
    };

    return {
        clientTask,
        setClientTask,
        errorState,
        setErrorState,
        addressTabErrorState,
        taskInformationTabErrorState,
        inventoryItemsTabErrorState,
        notesTabErrorState,

        hasIssuesAddressTab,
        hasIssuesTaskInformationTab,
        hasIssuesInventoryItemsTab,
        hasIssuesNotesTab,
        hasIssuesModal,

        tabIssuesMap
    };
};

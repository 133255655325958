import React from 'react';
import { Box as ThemeBox, BoxProps as ThemeBoxProps } from 'theme-ui';
import { styles } from '~/ui';

export interface BoxProps extends ThemeBoxProps {
    'data-popper-arrow'?: boolean;
    disabled?: boolean;
}

const Box = React.forwardRef<HTMLDivElement, BoxProps>(function Box(
    { sx, ...extra },
    ref
) {
    const clickableStyles =
        extra.disabled || extra.onClick ? styles.interactions.clickable : {};
    return <ThemeBox ref={ref} sx={{ ...clickableStyles, ...sx }} {...extra} />;
});

export default Box;

import { TimeWindow } from './CommonTypes';

export enum CustomMappingTypes {
    DELIVERY_SERVICE_TIME = 'deliveryServiceTime',
    PICKUP_SERVICE_TIME = 'pickupServiceTime',
    PROPS_DELIVERY_WINDOW = 'props.deliveryWindow',
    PROPS_PICKUP_WINDOW = 'props.pickupWindow'
}

/**
 * Describes task property values that will be set as the defualt initial value when creating new tasks
 */
export interface CustomMappingDefaultProps {
    /**
     * The default `pickupServiceTime`
     *
     * Expects an ISO duration string like:
     * + hour and minutes: `PT1H15M`
     * + hour only: `PT1H`
     * + minutes only: `PT30M`
     */
    [CustomMappingTypes.PICKUP_SERVICE_TIME]?: string;

    /**
     * The default `pickupWindow`
     *
     * Expects time window provided in 24-hour time
     * Supports a single time window
     */
    [CustomMappingTypes.PROPS_PICKUP_WINDOW]?: TimeWindow;

    /**
     * The default `deliveryServiceTime`
     *
     * Expects an ISO duration string like:
     * + hour and minutes: `PT1H15M`
     * + hour only: `PT1H`
     * + minutes only: `PT30M`
     */
    [CustomMappingTypes.DELIVERY_SERVICE_TIME]?: string;

    /**
     * The default `deliveryWindow`
     *
     * Expects time window provided in 24-hour time
     * Supports a single time window
     */
    [CustomMappingTypes.PROPS_DELIVERY_WINDOW]?: TimeWindow;
}

/**
 * Describes the shape of the CustomMappingApi.get() response
 *
 * The endpoint returns the `customMapping.json` value from the following Amazon S3 bucket:
 *
 * `wisesys-uploads/pipeline/customMappings/{{CLIENT_ID}}/customMapping.json`
 *
 * This interface partially implements the `customMapping.json` schema.
 * It will only include properties that are in-use by the application
 *
 * @see {@link https://wiki.wisesys.info/en/imp-and-int/self-service-onboarding customMapping.json}
 */
export interface ApiCustomMapping {
    /**
     * Default values for various fields should it not be provided by the user
     */
    defaults?: CustomMappingDefaultProps;
}

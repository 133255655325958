import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { resetOnLogout } from '~/reducers/common-actions';
import type { RootState } from '~/store';
import tasksApi, { TaskMetrics } from '~/api/TasksApi';

/**
 * The task metrics state
 */
export type TaskMetricsState = Required<TaskMetrics>;

const emptyMetrics: TaskMetricsState = {
    unassigned: 0,
    planned: 0,
    dispatched: 0,
    completed: 0,
    canceled: 0,
    total: 0
};

export const updateTaskMetrics = createAsyncThunk(
    'taskMetrics/updateTaskMetrics',
    async (
        routeDate: string,
        { getState, fulfillWithValue, rejectWithValue }
    ) => {
        try {
            const { activeClients = {} } = getState() as RootState;
            const isMultiAccess = Object.keys(activeClients).length > 1;
            const taskMetricsResponse = await tasksApi.getMetrics({
                date: routeDate,
                isMultiAccess
            });
            const taskMetrics = taskMetricsResponse.data.data;
            return fulfillWithValue(taskMetrics);
        } catch (error) {
            return rejectWithValue(emptyMetrics);
        }
    }
);

export const taskMetricsSlice = createSlice({
    name: 'taskMetrics',
    initialState: emptyMetrics,
    reducers: {
        setTaskMetrics: (
            state: TaskMetricsState,
            action: PayloadAction<Required<TaskMetrics>>
        ): TaskMetricsState => {
            return action.payload;
        },
        resetTaskMetrics: (): TaskMetricsState => {
            return emptyMetrics;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetOnLogout, () => {
            return emptyMetrics;
        });
        builder.addCase(
            updateTaskMetrics.fulfilled,
            (
                state: TaskMetricsState,
                action: PayloadAction<Required<TaskMetrics>>
            ) => {
                state = action.payload;
                return state;
            }
        );
        builder.addCase(
            updateTaskMetrics.rejected,
            (state: TaskMetricsState) => {
                state = emptyMetrics;
                return state;
            }
        );
    }
});

export const { setTaskMetrics, resetTaskMetrics } = taskMetricsSlice.actions;

export const selectTaskMetrics = (state: RootState) => state.taskMetrics;

export default taskMetricsSlice.reducer;

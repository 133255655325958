import React from 'react';
import { useTranslation } from 'react-i18next';
import { DuplicateTaskModalInvoices } from '../DuplicateTaskModalInvoices';
import { DuplicateTaskModalCollapsible } from '../DuplicateTaskModalCollapsible';

export const DuplicateTaskModalChooseItems = () => {
    const { t } = useTranslation('duplicateTaskModal');

    return (
        <DuplicateTaskModalCollapsible title={t('action.title')}>
            <DuplicateTaskModalInvoices />
        </DuplicateTaskModalCollapsible>
    );
};

import { Duration, DateTime, Zone, DurationUnit } from 'luxon';

/**
 * Converts seconds to hours and minutes
 *
 * @param {Number} seconds
 * @returns {Object} An object with hours (number) and minutes (number)
 */
export const mapSecondsToHoursAndMinutes = (seconds: number) => {
    const duration = Duration.fromObject({ seconds });
    const { hours, minutes } = duration
        .shiftTo('hours', 'minutes')
        .normalize()
        .toObject();

    return {
        hours: hours || 0,
        minutes: minutes || 0
    };
};

/**
 * Convert seconds to number of minutes
 *
 * @param { Number } seconds - The number of seconds to calculate the duration from
 * @returns { Object } An object representing the duration in minutes
 */
export const convertSecondsToMinutes = (seconds: number) => {
    return Duration.fromObject({ seconds }).shiftTo('minutes').toObject();
};

/**
 * Converts an ISO string representing a duration to a specified unit
 * @param {string} durationString - The ISO string representing the duration
 * @param {string} unit - The unit to convert to ('milliseconds' or 'hours')
 * @returns {number} The duration in the specified unit
 */
export const convertDuration = (
    durationString: string,
    unit: DurationUnit = 'hours'
): number => {
    const duration = Duration.fromISO(durationString);

    if (!duration.isValid) {
        return NaN;
    }

    return duration.as(unit);
};

/**
 * Converts a number to ISO duration
 * @param {number} minutes
 * @returns {string} durationString - The ISO string representing the duration */
export const convertMinutesToISO = (minutes: number): string => {
    return Duration.fromObject({ minutes }).toISO();
};

/**
 * Formats a given time while considering the specified time zone
 *
 * @param time - The time to be formatted, as a string or DateTime object
 * @param timeZone - The specified time zone
 * @param format - The format to be used for the output string. Default is 't'
 * @param isClientTimezoneFlagEnabled - Flag indicating whether to format the time using the client's time zone
 * @param shouldFormatTime - Whether to apply formatting to the output string. Default is true
 * @returns The formatted time as a string, or null if the input time is invalid
 */
export const formatTimeInTimeZone = ({
    time,
    timeZone,
    isClientTimezoneFlagEnabled,
    format = 't',
    shouldFormatTime = true
}: {
    time: string | DateTime;
    isClientTimezoneFlagEnabled?: boolean;
    timeZone?: string;
    format?: string;
    shouldFormatTime?: boolean;
}) => {
    const srcDateTime =
        typeof time === 'string' ? DateTime.fromISO(time) : time;

    if (!srcDateTime?.isValid) return null;

    const dateTime = isClientTimezoneFlagEnabled
        ? srcDateTime.setZone(timeZone)
        : srcDateTime;

    return shouldFormatTime ? dateTime.toFormat(format) : dateTime;
};

/**
 * Gets the timezone offset in minutes
 *
 * @param timeZone - The specified time zone
 * @returns {number} The offset in minutes for the specified time zone
 */
export const getTimezoneOffset = ({ timeZone }: { timeZone?: string }) => {
    return DateTime.now().setZone(timeZone).offset;
};

/**
 * Sets the provided 24-hour local time into the target timezone
 *
 * @example
 * The provided time represents a time on the target timezone
 * ```
 * const dateTime = setLocalTimeToZone('08:00', 'America/New_York')
 * // The result `dateTime` represents `8:00 AM, Eastern Time`,
 * // even if the user is on `America/Los Angeles` or `Pacific Time`
 * ```
 *
 * @example
 * The provided time represents a time on the user's system timezone
 * ```
 * const dateTime = setLocalTimeToZone('08:00')
 * // if user is on `America/New_York`, The result `dateTime` represents `8:00 AM, Eastern Time`
 * // if user is on `America/Los Angeles`, The result `dateTime` represents `8:00 AM, Pacific Time`
 * ```
 */
export const setLocalTimeToZone = (isoTime: string, zone?: string | Zone) => {
    return DateTime.fromISO(isoTime).setZone(zone, {
        keepLocalTime: true
    });
};

/**
 * Shifts the provided 24-hour local time into the target timezone
 *
 * @example
 * The provided time represents a time on the user's system timezone
 * Shift the time to a local time set in the target timezome
 *
 * ```
 * const dateTime = shiftLocalTimeToZone('08:00', 'America/New_York')
 * // The local system time represents `8:00 AM, UTC`
 * // The result `dateTime` represents `5:00 AM, Eastern Time`
 * ```
 *
 * @example
 * The provided time represents a time on the user's system timezone
 * ```
 * const dateTime = shiftLocalTimeToZone('08:00')
 * // if user is on `America/New_York`, The result `dateTime` represents `8:00 AM, Eastern Time`
 * // if user is on `America/Los Angeles`, The result `dateTime` represents `8:00 AM, Pacific Time`
 * ```
 */
export const shiftLocalTimeToZone = (isoTime: string, zone?: string | Zone) => {
    return DateTime.fromISO(isoTime).setZone(zone);
};

export const getDurationInMinutes = (
    isoDuration: string
): number | undefined => {
    const duration = Duration.fromISO(isoDuration);

    if (!duration.isValid) return;

    const { minutes } = duration.shiftTo('minutes').toObject();
    return Math.ceil(minutes as number);
};

export const formatServiceTimeToDuration = (value: string | undefined) => {
    const serviceTimeNum = Number(value);
    if (value === null || value === '' || Number.isNaN(serviceTimeNum)) {
        return value;
    }
    return convertMinutesToISO(Number(serviceTimeNum));
};

export default {
    mapSecondsToHoursAndMinutes,
    getDurationInMinutes,
    convertMinutesToISO,
    convertSecondsToMinutes,
    formatTimeInTimeZone,
    formatServiceTimeToDuration,
    convertDuration,
    getTimezoneOffset
};

import { getCurrentHost, getLocalStorageToggle } from '~/utils/general-utils';

export { default as createWebStorage } from 'redux-persist/lib/storage/createWebStorage';

const ENABLE_REDUX_DEV_TOOLS = 'enableReduxDevTools';

export const createNoopStorage = () => {
    return {
        getItem() {
            return Promise.resolve(null);
        },
        setItem(_key: unknown, value: unknown) {
            return Promise.resolve(value);
        },
        removeItem() {
            return Promise.resolve();
        }
    };
};

export const isReduxDevToolsEnabled = () => {
    try {
        const { isLocal, isStage, isSandbox } = getCurrentHost();
        const isEnabledByCurrentHost = isLocal || isStage || isSandbox;
        const isEnabledByStorage = getLocalStorageToggle(
            ENABLE_REDUX_DEV_TOOLS
        );
        const isEnabled = isEnabledByCurrentHost || isEnabledByStorage;
        return Boolean(isEnabled);
    } catch (e) {
        return false;
    }
};

import React from 'react';
import classNames from 'classnames';
import { Collapsible, Icon } from '~/ui';
import { IconSizes } from '~/ui/components/Icon/IconSizes';
import { DuplicateTaskModalCollapsibleProps } from './types';
import './DuplicateTaskModalCollapsible.scss';

const DuplicateTaskModalCollapsibleHeader = ({
    title,
    subtitle,
    step,
    isReady,
    'data-testid': dataTestId
}: DuplicateTaskModalCollapsibleProps) => {
    const rootClassName = 'duplicate-task-modal-collapsible-header';

    const elementClassName = classNames(rootClassName, '_d-flex', '_ai-center');
    const statusClassName = classNames(
        `${rootClassName}__status`,
        '_d-flex',
        '_jc-center',
        '_ai-center',
        {
            [`${rootClassName}__status--ready`]: isReady
        }
    );
    const readyIconClassName = `${rootClassName}__ready-icon`;
    const headerClassName = classNames(`${rootClassName}__header`, '_d-flex');
    const titleClassName = `${rootClassName}__title`;
    const subtitleClassName = `${rootClassName}__subtitle`;

    const statusTestId = `${dataTestId}-status`;
    const readyIconTestId = `${dataTestId}-ready-icon`;
    const titleTestId = `${dataTestId}-title`;
    const subtitleTestId = `${dataTestId}-subtitle`;

    return (
        <div className={elementClassName} data-testid={dataTestId}>
            <div className={statusClassName} data-testid={statusTestId}>
                {isReady && (
                    <Icon
                        icon="checkLine"
                        size={IconSizes.S}
                        color="comet"
                        className={readyIconClassName}
                        data-testid={readyIconTestId}
                    />
                )}
                {!isReady && step}
            </div>
            <div className={headerClassName}>
                <div className={titleClassName} data-testid={titleTestId}>
                    {title}
                </div>
                <div className={subtitleClassName} data-testid={subtitleTestId}>
                    {subtitle}
                </div>
            </div>
        </div>
    );
};

export const DuplicateTaskModalCollapsible = ({
    title,
    subtitle,
    step,
    isReady,
    children,
    'data-testid': dataTestId
}: DuplicateTaskModalCollapsibleProps) => {
    const rootClassName = 'duplicate-task-modal-collapsible';
    const elementTestId = dataTestId || rootClassName;
    const headerTestId = `${elementTestId}__header`;

    const getCardHeader = () => {
        return (
            <DuplicateTaskModalCollapsibleHeader
                title={title}
                subtitle={subtitle}
                step={step}
                isReady={isReady}
                data-testid={headerTestId}
            />
        );
    };

    return (
        <Collapsible
            className={rootClassName}
            data-testid={elementTestId}
            iconClose="chevronDown"
            iconOpen="chevronUp"
            iconColor="galaxy-800-alt"
            iconSize={IconSizes.L}
            iconPosition={Collapsible.iconPosition.RIGHT}
            cardHeader={getCardHeader()}
            initOpen
        >
            {children}
        </Collapsible>
    );
};

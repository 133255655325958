import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TaskTypes, ApiEquipment } from '~/api/types';
import type { RootState } from '~/store';

const DEFAULT_SELECTED_TASKTYPE = TaskTypes.DELIVERY;

interface AddTaskState {
    isOpenAddTaskModal: boolean;
    selectedTaskType: TaskTypes;
    selectedTabIndex: number;
    selectedEquipment?: ApiEquipment;
    isSaveAndAssignTaskMode: boolean;
    isAssignNewTaskToDispatchedDriver: boolean;
}

export const initialState: AddTaskState = {
    isOpenAddTaskModal: false,
    selectedTaskType: DEFAULT_SELECTED_TASKTYPE,
    selectedTabIndex: 0,
    isSaveAndAssignTaskMode: false,
    isAssignNewTaskToDispatchedDriver: false
};

export const addTaskSlice = createSlice({
    name: 'addTask',
    initialState,
    reducers: {
        setIsOpenAddTaskModal: (
            state: AddTaskState,
            action: PayloadAction<boolean>
        ) => {
            return {
                ...state,
                isOpenAddTaskModal: action.payload
            };
        },
        setSelectedTaskType: (
            state: AddTaskState,
            action: PayloadAction<TaskTypes>
        ) => {
            return {
                ...state,
                selectedTaskType: action.payload
            };
        },
        setSelectedEquipment: (
            state: AddTaskState,
            action: PayloadAction<ApiEquipment | undefined>
        ) => {
            return {
                ...state,
                selectedEquipment: action.payload
            };
        },
        setSelectedTabIndex: (
            state: AddTaskState,
            action: PayloadAction<number>
        ) => {
            return {
                ...state,
                selectedTabIndex: action.payload
            };
        },
        setIsSaveAndAssignTaskMode: (
            state: AddTaskState,
            action: PayloadAction<boolean>
        ) => {
            return {
                ...state,
                isSaveAndAssignTaskMode: action.payload
            };
        },
        setIsAssignNewTaskToDispatchedDriver: (
            state: AddTaskState,
            action: PayloadAction<boolean>
        ) => {
            return {
                ...state,
                isAssignNewTaskToDispatchedDriver: action.payload
            };
        }
    }
});

export const {
    setIsOpenAddTaskModal,
    setSelectedTaskType,
    setSelectedEquipment,
    setSelectedTabIndex,
    setIsSaveAndAssignTaskMode,
    setIsAssignNewTaskToDispatchedDriver
} = addTaskSlice.actions;

export const selectIsOpenAddTaskModal = (state: RootState): boolean =>
    state.addTask.isOpenAddTaskModal;

export const selectSelectedTaskType = (state: RootState): TaskTypes =>
    state.addTask.selectedTaskType;

export const selectSelectedEquipment = (
    state: RootState
): ApiEquipment | undefined => state.addTask.selectedEquipment;

export const selectSelectedTabIndex = (state: RootState): number =>
    state.addTask.selectedTabIndex;

export const selectIsSaveAndAssignTaskMode = (state: RootState): boolean =>
    state.addTask.isSaveAndAssignTaskMode;

export const selectIsAssignNewTaskToDispatchedDriver = (
    state: RootState
): boolean => state.addTask.isAssignNewTaskToDispatchedDriver;

export default addTaskSlice.reducer;

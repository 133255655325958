import { UseQueryOptions } from 'react-query';
import { ApiCustomMapping } from '~/api/types';
import { GetCustomMappingParams } from '~/api/CustomMappingApi/types';
import constants from '~/utils/constants';

export const REACT_QUERY_KEY = constants.reactQueryKeys.CUSTOM_MAPPING;

export const EMPTY_CUSTOM_MAPPING = { defaults: {} };

export type UseGetCustomMappingReactQueryOptions = Omit<
    UseQueryOptions<ApiCustomMapping, Error>,
    'queryKey' | 'queryFn'
>;

export interface UseGetCustomMappingParams {
    /**
     * The API options for `EquipmentApi.getEquipment()`
     *
     */
    apiOptions: GetCustomMappingParams;

    /**
     * Additional react query options
     */
    reactQueryOptions?: UseGetCustomMappingReactQueryOptions;
}

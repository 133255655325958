import React, {
    useState,
    useImperativeHandle,
    useMemo,
    useCallback
} from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { DateTime } from 'luxon';

import {
    useToastContext,
    useFetchMainClientAddress,
    useSelectedClients,
    useClientTimezone
} from '~/hooks';

import { setRoutePlansByDate } from '~/reducers/routePlansByDateSlice';
import { selectDateOnly } from '~/reducers/selectedDateSlice';

import { useModal, ActionBarItem } from '~/ui';
import { ModalSize } from '~/ui/components/Modal/types';
import { DateSelectModalProps, DateSelectModalRef } from './types';

import DateSelectModalContent from './DateSelectModalContent';
import { fetchRoutePlansByDateAndUniqueRouteDates } from './utils';
import DateUtils from '~/utils/date-utils';

const DateSelectModal = React.forwardRef<
    DateSelectModalRef,
    DateSelectModalProps
>(function DateSelectModal({ onClickDone, isDateAgnosticPage }, ref) {
    const {
        hideModal: hideDateModal,
        showModal: showDateModal,
        modal: DateModal
    } = useModal();
    const { t } = useTranslation('translation');
    const { addToast } = useToastContext();

    const [uniqueDates, setUniqueDates] = useState<string[]>([]);
    const selectedDate = useSelector(selectDateOnly);
    const { areAllMultiClientsSelected, userHasMultiAccess } =
        useSelectedClients();
    const { timezone: clientTimezone } = useClientTimezone();

    const dispatch = useDispatch();
    const response = useFetchMainClientAddress();
    const { data: mainClientAddress } = response;
    const { name } = mainClientAddress || {};

    const addressName = useMemo(() => {
        if (areAllMultiClientsSelected) {
            return t('allClients');
        }
        if (!name && userHasMultiAccess) {
            return t('noClientSelected');
        }
        return name;
    }, [name, userHasMultiAccess, areAllMultiClientsSelected, t]);

    const initDateModalContent = useCallback(() => {
        (async () => {
            try {
                const { routePlansByDate, uniqueRouteDates } =
                    await fetchRoutePlansByDateAndUniqueRouteDates();

                dispatch(setRoutePlansByDate(routePlansByDate));
                setUniqueDates(uniqueRouteDates);
            } catch (e) {
                console.error(e);
                addToast({
                    message: t('error:routesFetchError'),
                    variant: 'error'
                });
            }
            showDateModal();
        })();
    }, [addToast, dispatch, showDateModal, t]);

    const dateStationFormatted = useMemo(() => {
        if (!selectedDate || !name) {
            if (userHasMultiAccess) {
                return `${t('DatesAndStations')} - ${t(
                    'noClientDateSelected'
                )}`;
            }
            return `${t('DatesAndStations')} - ${t('noDateSelected')}`;
        }

        const dateTime = DateTime.fromISO(selectedDate);
        const dateString = dateTime
            .setLocale(navigator.language)
            .toLocaleString({
                weekday: 'short',
                month: 'short',
                day: 'numeric'
            });

        const formattedOffset = DateUtils.formatDateOffset(
            dateTime.setZone(clientTimezone).offset
        );

        if (isDateAgnosticPage) return addressName || '';
        if (!addressName) return dateString;

        return `${dateString}, ${addressName}, (${formattedOffset})`;
    }, [
        selectedDate,
        name,
        isDateAgnosticPage,
        addressName,
        userHasMultiAccess,
        t,
        clientTimezone
    ]);

    // expose methods to `ref`
    useImperativeHandle(ref, () => ({
        initDateModalContent,
        setUniqueDates
    }));

    return (
        <>
            <ActionBarItem
                details={dateStationFormatted}
                onClick={initDateModalContent}
                type="noIcon"
            />
            <DateModal hasCustomHeader variant={ModalSize.DATE}>
                <DateSelectModalContent
                    addressName={addressName}
                    dates={uniqueDates}
                    hideModal={hideDateModal}
                    onClickDone={onClickDone}
                    isDateAgnosticPage={isDateAgnosticPage}
                />
            </DateModal>
        </>
    );
});

export default DateSelectModal;

import { useState, useEffect } from 'react';
import { useSelectedMapRoutes } from '~/components/MapPage/useSelectedMapRoutes';
import { useSelector } from 'react-redux';
import { selectHiddenRoutes } from '~/reducers/hiddenRoutesSlice';
import { useIsolatedRoutes } from '~/hooks';
import { selectSelectedDrawerCardId } from '~/reducers/selectedDrawerCardIdSlice';
import { PlanRoute } from '~/data-classes';

import { checkShouldFilterOutRoute } from '../utils';

type UseRouteFilteringProps = {
    routesLevelData: PlanRoute[];
};

export const useRouteFiltering = ({
    routesLevelData
}: UseRouteFilteringProps) => {
    const hiddenRoutes = useSelector(selectHiddenRoutes);
    const selectedDrawerRouteId = useSelector(selectSelectedDrawerCardId);

    const { selectedMapRoutes } = useSelectedMapRoutes({
        planRoutes: routesLevelData
    });

    const { hasIsolatedRoutes } = useIsolatedRoutes();
    const [visibleOnMapRouteData, setVisibleOnMapRouteData] = useState<
        PlanRoute[]
    >([]);

    useEffect(() => {
        const visibleRoutes = [];
        for (const routeLevelData of routesLevelData) {
            const { numTasks, clientRouteId } = routeLevelData;
            if (!numTasks) {
                continue;
            }
            const shouldFilterOutNew = checkShouldFilterOutRoute(
                clientRouteId,
                hiddenRoutes,
                selectedMapRoutes,
                hasIsolatedRoutes,
                selectedDrawerRouteId
            );
            if (!shouldFilterOutNew) {
                visibleRoutes.push(routeLevelData);
            }
        }
        setVisibleOnMapRouteData(visibleRoutes);
    }, [
        routesLevelData,
        hiddenRoutes,
        hasIsolatedRoutes,
        selectedDrawerRouteId,
        selectedMapRoutes
    ]);

    return {
        visibleOnMapRouteData
    };
};

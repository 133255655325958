import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectSelectedMapRoutes } from '~/reducers/selectedMapRoutesSlice';
import {
    selectMapRouteMode,
    selectShowUnassignedTasks,
    selectShouldFitPlanMapToBounds,
    setShouldFitPlanMapToBounds
} from '~/reducers/mapSettingsSlice';
import { selectSelectedDrawerCardId } from '~/reducers/selectedDrawerCardIdSlice';
import { setLastPlanMapZoom } from '~/reducers/lastPlanMapZoomSlice';
import { useIsolatedRoutes } from '~/hooks';

import { ConfigurableMapRouteMode } from '~/reducers/mapSettingsSlice/types';
import constants from '~/utils/constants';

export const useFitMapToBounds = () => {
    const dispatch = useDispatch();

    const setShouldFitToBounds = useCallback(
        (value: boolean) => {
            return dispatch(setShouldFitPlanMapToBounds(value));
        },
        [dispatch]
    );

    const shouldFitToBounds = useSelector(selectShouldFitPlanMapToBounds);

    return {
        shouldFitToBounds,
        setShouldFitToBounds
    };
};

export const useMapBoundingEffects = () => {
    const dispatch = useDispatch();
    const { setShouldFitToBounds } = useFitMapToBounds();
    const selectedMapRoutes = useSelector(selectSelectedMapRoutes);
    const selectedDrawerRouteId = useSelector(selectSelectedDrawerCardId);
    const mapRouteMode = useSelector(selectMapRouteMode);
    const showUnassignedTasks = useSelector(
        selectShowUnassignedTasks(
            mapRouteMode as unknown as ConfigurableMapRouteMode
        )
    );
    const { isRouteMultiSelectInProgress } = useIsolatedRoutes();

    const isActivelyMultiSelecting =
        isRouteMultiSelectInProgress && selectedMapRoutes.length > 1;

    const hasStartedMultiSelect =
        isRouteMultiSelectInProgress && selectedMapRoutes.length === 1;

    // fit bounds when task, route or stops selection change
    useEffect(() => {
        if (isActivelyMultiSelecting) {
            return;
        }
        setShouldFitToBounds(true);
        if (hasStartedMultiSelect) {
            dispatch(
                setLastPlanMapZoom(constants.mapOptionSettings.DEFAULT_ZOOM)
            );
        }
    }, [
        setShouldFitToBounds,
        isActivelyMultiSelecting,
        hasStartedMultiSelect,
        showUnassignedTasks,
        selectedDrawerRouteId,
        selectedMapRoutes,
        dispatch
    ]);
};

import {
    CognitoUserPool,
    CognitoUser,
    CognitoUserSession,
    AuthenticationDetails,
    CognitoAccessToken,
    CognitoIdToken,
    CognitoRefreshToken
} from 'amazon-cognito-identity-js';
import axios from 'axios';
import config from './aws-cognito-config';
import { gotoAwsIncognitoLogoutPage } from './aws-cognito-utils';
import apiServers from '~/servers/api-servers';
import socketServers from '~/servers/socket-servers';
import socketInstance from '~/utils/socket/socket-instance';
import constants from '~/utils/constants';
import { initAxios } from '~/utils/app-utils';
import { promiseTimer } from '~/utils/general-utils';

const environment = config[window.location.hostname] || config.default;
const THRESHOLD_TIME_IN_MILLIS = 600000; // 10 min
const WAIT_TIME_IN_MILLIS = 1000; // 1 second
const apiServerURL = apiServers.getURL();
const socketServerURL = socketServers.getURL();

export default {
    poolData: new CognitoUserPool({
        UserPoolId: environment.poolId,
        ClientId: environment.clientId
    }),
    getUser() {
        return this.poolData.getCurrentUser();
    },
    authenticateUsernameLogin(credentials = {}) {
        const { username, password } = credentials;

        if (!password || !username) return;

        const userData = {
            Username: username.toLowerCase(),
            Password: password,
            Pool: this.poolData
        };
        const cognitoUser = new CognitoUser(userData);
        cognitoUser.setAuthenticationFlowType('USER_PASSWORD_AUTH');

        const authenticationDetails = new AuthenticationDetails({
            Username: credentials.username,
            Password: credentials.password
        });
        return new Promise((resolve, reject) => {
            cognitoUser.authenticateUser(authenticationDetails, {
                // on success tokens get added to local storage
                onSuccess: (session) => {
                    this.createUserSession(
                        session,
                        constants.productTypes.INTERSTELLAR
                    );
                    resolve(session);
                },
                onFailure: () => {
                    reject('Invalid Credentials');
                },
                newPasswordRequired: (userAttributes) => {
                    delete userAttributes.email;
                    delete userAttributes.phone_number;
                    delete userAttributes.email_verified;
                    delete userAttributes.phone_number_verified;
                    userAttributes.given_name = credentials.username;
                    userAttributes.family_name = credentials.username;

                    cognitoUser.completeNewPasswordChallenge(
                        '84Sherman',
                        userAttributes,
                        {
                            onSuccess: (session) => {
                                this.createUserSession(
                                    session,
                                    constants.productTypes.INTERSTELLAR
                                );
                                resolve(session);
                            },
                            onFailure: (error) => {
                                reject(error);
                            }
                        }
                    );
                }
            });
        });
    },
    async authenticateUserFromOrigin(tokens) {
        await this.cacheTokens(
            tokens.accessToken,
            tokens.idToken,
            tokens.refreshToken
        );
        return new Promise((resolve, reject) => {
            const user = this.poolData.getCurrentUser();
            if (user) {
                user.getSession((error, session) => {
                    if (error) {
                        reject('Invalid Session');
                    } else {
                        this.createUserSession(
                            session,
                            constants.productTypes.SOLAR
                        );
                        resolve(session);
                    }
                });
            } else {
                reject('No User');
            }
        });
    },
    /**
     * Redirect to an identity provider auth page as part of initial authentication flow.
     * @param {Object} data
     * @param {"google"|"saml"} data.provider - type of ID provider to use
     * @param {String} data.identifier - in case of SAML corporate email domain identifying the specific SAML
     * provider
     */
    authenticateWithSSO(data) {
        const cognitoConfig =
            config[window.location.hostname] || config.default;
        const authorizeUrl =
            `https://${cognitoConfig.domain}.amazoncognito.com/oauth2/authorize?` +
            `redirect_uri=${window.location.origin}&response_type=code` +
            `&client_id=${cognitoConfig.clientId}`;
        switch (data.provider) {
            case 'google':
                window.location.href = `${authorizeUrl}&identity_provider=Google`;
                break;
            case 'saml':
                window.location.href = `${authorizeUrl}&idp_identifier=${data.identifier}`;
                break;
            default:
                console.info('Unknown auth provider');
                break;
        }
    },
    async authenticateCustomLogin(authCode) {
        const cognitoConfig =
            config[window.location.hostname] || config.default;
        const result = await fetch(
            `https://${cognitoConfig.domain}.amazoncognito.com/oauth2/token`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body:
                    `grant_type=authorization_code&code=${authCode}&` +
                    `client_id=${cognitoConfig.clientId}&redirect_uri=${window.location.origin}`
            }
        );
        if (result.ok) {
            const jsonBody = await result.json();
            await this.cacheTokens(
                jsonBody.access_token,
                jsonBody.id_token,
                jsonBody.refresh_token
            );
            const cognitoIdToken = new CognitoIdToken({
                IdToken: jsonBody.id_token
            });
            const userDetails = Object.assign(
                this.parseIdToken(cognitoIdToken),
                {
                    accessToken: jsonBody.access_token,
                    idToken: cognitoIdToken.getJwtToken()
                }
            );
            const clientIds = this.extractClientIds(userDetails);
            const tokens = {
                accessToken: jsonBody.access_token,
                idToken: jsonBody.id_token,
                refreshToken: jsonBody.refresh_token
            };
            const mcwAppInit = this.createAppInit(
                tokens,
                clientIds,
                userDetails,
                constants.productTypes.INTERSTELLAR
            );
            localStorage.setItem(
                constants.localStorageKeys.MCW_APP_INIT,
                JSON.stringify(mcwAppInit)
            );
            initAxios(mcwAppInit);
            return mcwAppInit;
        }
        throw new Error('Failed to authenticate custom login through cognito');
    },
    parseIdToken(idToken) {
        return {
            id: idToken.payload.sub,
            username: idToken.payload['cognito:username'],
            email: idToken.payload.email,
            firstname: idToken.payload.given_name,
            lastname: idToken.payload.family_name,
            access: JSON.parse(idToken.payload['custom:access'])
        };
    },
    createUserSession(session, appType) {
        const tokens = {
            accessToken: session.getAccessToken().getJwtToken(),
            idToken: session.getIdToken().getJwtToken(),
            refreshToken: session.getRefreshToken().getToken()
        };
        const userDetails = Object.assign(
            this.parseIdToken(session.getIdToken(), {
                accessToken: tokens.accessToken,
                idToken: tokens.idToken
            })
        );
        const clientIds = this.extractClientIds(userDetails);
        const mcwAppInit = this.createAppInit(
            tokens,
            clientIds,
            userDetails,
            appType
        );
        localStorage.setItem(
            constants.localStorageKeys.MCW_APP_INIT,
            JSON.stringify(mcwAppInit)
        );
        initAxios(mcwAppInit);
        return mcwAppInit;
    },
    async cacheTokens(accessToken, idToken, refreshToken) {
        const cognitoIdToken = new CognitoIdToken({
            IdToken: idToken
        });
        const cognitoAccessToken = new CognitoAccessToken({
            AccessToken: accessToken
        });
        const cognitoRefreshToken = new CognitoRefreshToken({
            RefreshToken: refreshToken
        });
        const userData = {
            Username: cognitoIdToken.payload.sub,
            Pool: this.poolData
        };
        const cognitoUser = new CognitoUser(userData);
        const cognitoSession = new CognitoUserSession({
            IdToken: cognitoIdToken,
            AccessToken: cognitoAccessToken,
            RefreshToken: cognitoRefreshToken
        });
        // setSignInUserSession adds same tokens with slightly different keys to local storage
        await cognitoUser.setSignInUserSession(cognitoSession);
    },
    extractClientIds(userDetails) {
        return userDetails.access.map((client) => client.client);
    },
    isSessionExpiring(poolData) {
        return new Promise((resolve, reject) => {
            const user = poolData.getCurrentUser();
            if (user) {
                user.getSession(async (error, session) => {
                    if (error) {
                        reject(error);
                    } else {
                        const expiryDiff =
                            session.getAccessToken().getExpiration() * 1000 -
                            Date.now();
                        const isSessionExpiring =
                            expiryDiff <= THRESHOLD_TIME_IN_MILLIS;
                        if (!isSessionExpiring) {
                            resolve(session);
                        } else {
                            try {
                                const newSession = await this.refreshToken(
                                    session.getRefreshToken(),
                                    poolData
                                );
                                const mcwAppInit = JSON.parse(
                                    localStorage.getItem(
                                        constants.localStorageKeys.MCW_APP_INIT
                                    )
                                );
                                initAxios(mcwAppInit);
                                resolve(newSession);
                            } catch (err) {
                                reject(err);
                            }
                        }
                    }
                });
            } else {
                reject('No User');
            }
        });
    },
    refreshToken(refreshToken, poolData) {
        const user = poolData.getCurrentUser();
        return new Promise((resolve, reject) => {
            if (!user) reject('No User');
            user.refreshSession(refreshToken, (error, session) => {
                if (error) {
                    reject('Invalid Refresh Token');
                } else {
                    this.refreshAppInit(session);
                    resolve(session);
                }
            });
        });
    },
    createAppInit(tokens, clientIds, userDetails, appType) {
        return {
            data: {
                apiUrl: apiServerURL,
                clientIds,
                userDetails,
                socketUrl: socketServerURL,
                appType
            },
            requestHeaders: {
                authorization: tokens.accessToken,
                [constants.requestHeaders.WISE_CLIENT_ID]: clientIds[0],
                'x-oauth-idtoken': tokens.idToken
            }
        };
    },
    refreshAppInit(session) {
        const accessToken = session.getAccessToken().getJwtToken();
        const idToken = session.getIdToken().getJwtToken();
        const appInit = JSON.parse(
            localStorage.getItem(constants.localStorageKeys.MCW_APP_INIT)
        );
        appInit.requestHeaders.authorization = accessToken;
        appInit.requestHeaders['x-oauth-idtoken'] = idToken;
        localStorage.setItem(
            constants.localStorageKeys.MCW_APP_INIT,
            JSON.stringify(appInit)
        );
    },
    tearDownAxios() {
        delete axios.defaults.baseURL;
        delete axios.defaults.headers.common['Content-Type'];
        delete axios.defaults.headers.common.Authorization;
        delete axios.defaults.headers.common[
            constants.requestHeaders.WISE_CLIENT_ID
        ];
        delete axios.defaults.headers.common['X-OAuth-IDToken'];
    },
    async logout() {
        localStorage.removeItem(constants.localStorageKeys.MCW_APP_INIT);
        socketInstance.disconnectFromServer();
        this.tearDownAxios();

        /**
         * Wait for all teardown operations to complete
         *
         * Sometimes, previous teardown actions do not complete before the user signout.
         * This leads to stale states being persisted for the next login session
         */
        await promiseTimer(WAIT_TIME_IN_MILLIS);

        const user = this.poolData.getCurrentUser();
        if (user) {
            await user.signOut();
            gotoAwsIncognitoLogoutPage();
        }
    },
    forgotPassword(username) {
        const userData = {
            Username: username,
            Pool: this.poolData
        };
        const cognitoUser = new CognitoUser(userData);
        return new Promise((resolve, reject) => {
            cognitoUser.forgotPassword({
                onSuccess() {
                    resolve();
                },
                onFailure(err) {
                    reject(err);
                }
            });
        });
    },
    confirmForgotPassword(username, verificationCode, newPassword) {
        const userData = {
            Username: username,
            Pool: this.poolData
        };

        const cognitoUser = new CognitoUser(userData);
        return new Promise((resolve, reject) => {
            cognitoUser.confirmPassword(verificationCode, newPassword, {
                onSuccess() {
                    resolve();
                },
                onFailure(err) {
                    reject(err);
                }
            });
        });
    }
};

import { Duration } from 'luxon';

import generalUtils from '~/utils/general-utils/general-utils';
import { getParamErrorUUID, validateWithValidationMap } from '../utils';

import {
    ApiParamValidationErrorMessageMap,
    ValidationMap
} from '../utils/types';
import { GetCustomMappingParams } from './types';
import { CustomMappingTypes, TimeWindow } from '~/api/types';

export const getCustomMappingApiParamErrors = ({
    client
}: GetCustomMappingParams) => {
    const errorMessageMap: ApiParamValidationErrorMessageMap = {
        missing: 'Missing `client` parameter',
        invalid: 'Invalid `client` parameter, must be a UUID string'
    };

    return getParamErrorUUID(client, errorMessageMap);
};

const validateTimeWindows = (timeWindow: TimeWindow) => {
    if (timeWindow === undefined) {
        return true;
    }
    const { start, end } = timeWindow;
    if (!start || !end) {
        return false;
    }
    const TIME_24H_REGEX = /^(?:[0-9]|[01]\d|2[0-3]):[0-5]\d$/;
    const validStartTime = TIME_24H_REGEX.test(start);
    const validEndTime = TIME_24H_REGEX.test(end);
    return validStartTime && validEndTime;
};

const validateServiceTime = (serviceTime: string) => {
    try {
        if (serviceTime === undefined) {
            return true;
        }
        const duration = Duration.fromISO(serviceTime);
        return duration.isValid;
    } catch (e) {
        return false;
    }
};

const validateClientId = (clientId: string) => {
    return generalUtils.isValidUUID(clientId);
};

export const getCustomMappingParamUpdateErrors = (
    params: Record<string, unknown>
) => {
    const validationMap = {
        clientId: {
            validator: validateClientId,
            errorMessage:
                '`clientId` must be provided as a valid string identifier'
        },
        [CustomMappingTypes.PROPS_PICKUP_WINDOW as string]: {
            validator: validateTimeWindows,
            errorMessage:
                'Pickup time windows must be either undefined or an object {start: "H:mm", end: "H:mm"}'
        },
        [CustomMappingTypes.PROPS_DELIVERY_WINDOW as string]: {
            validator: validateTimeWindows,
            errorMessage:
                'Delivery time windows must be either undefined or an object {start: "H:mm", end: "H:mm"}'
        },
        [CustomMappingTypes.DELIVERY_SERVICE_TIME as string]: {
            validator: validateServiceTime,
            errorMessage:
                'Delivery service time must be either undefined or ISO Duration string'
        },
        [CustomMappingTypes.PICKUP_SERVICE_TIME as string]: {
            validator: validateServiceTime,
            errorMessage:
                'Pickup service time must be either undefined or ISO Duration string'
        }
    };

    return validateWithValidationMap(params, validationMap as ValidationMap);
};

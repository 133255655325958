import { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useUnleashContext } from '@unleash/proxy-client-react';

import { stopSessionReplay } from '~/datadogService';

import authService from '~/auth/aws-cognito-auth-service';

import clientsAPI from '~/api/ClientsApi';
import { parseSearch, sessionManager } from '~/api/SessionManager';
import { UsersApi } from '~/api/UsersApi';

import { initAxios } from '~/utils/app-utils';
import constants from '~/utils/constants';
import socketInstance from '~/utils/socket/socket-instance';
import { migrateAdminUserRoleToPermissionGroup } from '~/utils/user-groups-utils';

import { setAppInitialized } from '~/reducers/appGlobalsSlice';
import { setClients } from '~/reducers/clientsSlice';
import { resetOnLogout } from '~/reducers/common-actions';
import { selectCurrentPage } from '~/reducers/currentPageSlice';
import { setCurrentUser } from '~/reducers/currentUserSlice';

import { usePageNavigation } from '../usePageNavigation';

import { getMcwAppInitFromLocalStorage, refreshSessionToken } from './utils';
import { MCWAppInit } from './types';
import { ApiClient, AxiosApiResponse, PaginationMetadata } from '~/api/types';

export const useLogin = () => {
    const dispatch = useDispatch();
    const { goToPage } = usePageNavigation();
    const currentPage = useSelector(selectCurrentPage);
    const refreshTimerId = useRef<NodeJS.Timeout | number>(0);
    const { poolData } = authService;
    const updateContext = useUnleashContext();

    /**
     * Resets app state and redirects to the login page
     */
    const logout = useCallback(
        async (search?: Record<string, string>) => {
            const { sessionStorageKeys, url } = constants;
            sessionStorage.clear();

            /**
             * Set any search parameters to `sessionStorage` for use on the `LoginPage`
             *
             * AWS Cognito does not support passing any search parameters directly to the redirect URL
             *
             * Any desired search params for the `LoginPage` has passed to the `RootPage`
             * via `sessionStorage`
             */

            if (search) {
                sessionStorage.setItem(
                    sessionStorageKeys.LOGIN_PARAMS,
                    JSON.stringify(search)
                );
            }

            clearInterval(refreshTimerId.current as NodeJS.Timeout);
            stopSessionReplay();

            /**
             * Resets all slices back to initial state
             *
             * This only affects slices that implement `resetOnLogout`
             * as part of the `extraReducers` callback
             *
             * Slices that do not implement `resetOnLogout`
             * will have their current state values preserved in local storage
             */
            dispatch(resetOnLogout());

            /**
             * Logout from AWS Cognito
             *
             * Cognito will automatically redirect to the `HOME` page once it completes
             */
            await authService.logout();
            goToPage(url.LOGOUT, search);
        },
        [dispatch, goToPage]
    );

    /**
     * Clears the app state if the mcwAppInit data was removed from local storage
     */
    const handleGlobalLogout = useCallback(
        async (e: StorageEvent) => {
            const hasLoggedOut =
                e.key === constants.localStorageKeys.MCW_APP_INIT &&
                e.oldValue &&
                !e.newValue;

            if (hasLoggedOut) {
                await logout();
            }
        },
        [logout]
    );

    /**
     * Connects the client to the websocket server and joins the accessible client rooms
     */
    const initSocket = useCallback(
        (mcwAppInit: MCWAppInit) => {
            if (!mcwAppInit) {
                throw new Error('missing app init');
            }
            socketInstance.connectToSocketServer(
                mcwAppInit.data.socketUrl,
                mcwAppInit.requestHeaders.authorization,
                logout
            );
        },
        [logout]
    );

    /**
     * Sets the accessible clients in the store
     */
    const storeAllClients = useCallback(
        (response: AxiosApiResponse<ApiClient[], PaginationMetadata>) => {
            const clients = response.data.data;
            const clientsObjById = clients.reduce<Record<string, ApiClient>>(
                (clientsObj, client) => {
                    clientsObj[client.id] = client;
                    return clientsObj;
                },
                {}
            );
            dispatch(setClients(clientsObjById));
        },
        [dispatch]
    );

    /**
     * Fetches the user object for the logged in user and saves the user data to local storage
     */
    const updateUserDetails = useCallback(
        async (mcwAppInit: MCWAppInit) => {
            const userResponse = await UsersApi.getCurrentUser();
            const userDetails = userResponse.data.data;
            dispatch(setCurrentUser(userDetails));
            mcwAppInit.data.userDetails = userDetails;
            localStorage.setItem(
                constants.localStorageKeys.MCW_APP_INIT,
                JSON.stringify(mcwAppInit)
            );
        },
        [dispatch]
    );

    /**
     * Sets an interval to check if the access token is about to expire every 7 minutes.
     * Refreshes the tokens when needed.
     */
    const startSessionRefreshTimer = useCallback(() => {
        clearInterval(refreshTimerId.current as NodeJS.Timeout);
        refreshTimerId.current = setInterval(async () => {
            try {
                await refreshSessionToken(poolData);
            } catch (e) {
                console.error(e);
                await logout();
            }
        }, constants.timings.SESSION_REFRESH_INTERVAL);
    }, [logout, poolData]);

    /**
     * Attempts to obtain authorization tokens from Cognito or from Solar, depending on the
     * location's search paramters. Throws if none of the expected parameters are provided.
     */
    const login = async () => {
        const parsedSearch: Record<string, unknown> = parseSearch();
        if (parsedSearch.code) {
            return authService.authenticateCustomLogin(parsedSearch.code);
        }
        if (parsedSearch.origin && parsedSearch.path) {
            const tokens = await sessionManager.getTokensFromOrigin();
            if (tokens) {
                await authService.authenticateUserFromOrigin(tokens);
            }
            return getMcwAppInitFromLocalStorage();
        }
        throw new Error(
            'Attempted login without tokens or cross-domain origin information'
        );
    };

    /**
     * Gets tokens and initializes the app state on successfully login
     */
    useEffect(() => {
        const loginUser = async () => {
            try {
                const localCache = getMcwAppInitFromLocalStorage();

                const mcwAppInit =
                    localCache ?? ((await login()) as MCWAppInit);

                startSessionRefreshTimer();
                initAxios(mcwAppInit);

                const clientsResponse = await clientsAPI.get();
                initSocket(mcwAppInit);

                dispatch(setAppInitialized(true));
                await updateUserDetails(mcwAppInit);
                const { userDetails } = mcwAppInit.data;
                updateContext({ userId: userDetails.username.toLowerCase() });
                await migrateAdminUserRoleToPermissionGroup(
                    userDetails,
                    clientsResponse.data.data
                );
                storeAllClients(clientsResponse);
                if (currentPage === constants.url.LOGIN) {
                    goToPage(constants.url.HOME);
                }
                window.addEventListener('storage', handleGlobalLogout);
            } catch (e) {
                console.error(e);
                await logout();
            }
        };
        loginUser();
        return () => {
            window.removeEventListener('storage', handleGlobalLogout);
        };

        // DO NOT ADD `exhaustive-deps`, effect needs to run once
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, []);

    return {
        logout
    };
};
